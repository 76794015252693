import lgMp4 from './hitDifferent_lg.mp4';
import lgWebm from './hitDifferent_lg.webm';
import xsMp4 from './hitDifferent_xs.mp4';
import xsWebm from './hitDifferent_xs.webm';

export const hitDifferent = {
    lg: {
        mp4: lgMp4,
        webm: lgWebm
    },
    xs: {
        mp4: xsMp4,
        webm: xsWebm
    }
};