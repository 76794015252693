
import {Col, Container, Row, Spacer, spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';
import {Color, H2, H5} from 'UI/components/layout/Text';

import {FadeInAnimation} from 'UI/animations/shared';
import {useThemeColors} from 'UI/hooks/useThemeColors';

import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a text-focused component designed to highlight the unique design aspects of a product, such as the "Sonos Ace".
 * This component uses the `useThemeColors` hook to apply appropriate color theming to the text elements, enhancing readability and aesthetic appeal.
 * The text is presented in a column layout that adjusts responsively across different screen sizes, with motion effects to animate the text on viewport entry.
 * This design is effective for emphasizing key attributes like premium acoustic performance and comfort, making it ideal for marketing or informative sections on a webpage.
 * The structure includes headings and paragraphs with animations to attract attention and provide a dynamic user experience.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for the component, useful for testing purposes.
 * @returns A React component that displays animated text content, emphasizing the unique design features of a product in a visually appealing and engaging manner.
 *
 * @example
 * ```tsx
 * <UniqueDesignText testId="unique-design-text-section" />
 * ```
 */
const UniqueDesignText = ({testId}: ComponentProps) => {
    const colors = useThemeColors();

    return (
        <StyledContainer data-cy={testId} maxWidth={CONTAINER_FULLSIZE_WIDTH}>
            <InViewAnimContainer baseComponent={Row}>
                <Col md={6} offset={{md: 1}} xl={4} xs={12}>
                    <H2 as={motion.h2} variants={FadeInAnimation}>
                        <Color $color={colors.primaryFontColorLight}>Einzigartiges<br />Design</Color>
                    </H2>
                    <Spacer y={{
                        md: 12,
                        xs: 8
                    }}
                    />
                    <H5 as={motion.p} variants={FadeInAnimation}>
                        <Color $color={colors.secondaryFontColor}>
                            Jedes Element des Sonos Ace
                            wurde für eine erstklassige
                            Akustik und maximalen ganztägigen
                            Tragekomfort konzipiert.
                        </Color>
                    </H5>
                </Col>
            </InViewAnimContainer>
            <Spacer y={{md: 66, xs: 20}} />
        </StyledContainer>
    );
};

UniqueDesignText.displayName = 'UniqueDesignText';
UniqueDesignText.defaultProps = {testId: 'UniqueDesignText'};

export {UniqueDesignText};

const StyledContainer = styled(Container)`
    padding-block-start: ${spacing(20)};
`;