import {useScreenSize} from '@nfq/react-grid';

import {useThemeColors} from 'UI/hooks/useThemeColors';

/**
 * A custom React hook that provides responsive design utilities specifically for handling partner components or sections
 * within an application. It uses the `useScreenSize` hook to determine the current screen size and adjusts settings
 * accordingly, such as determining if the device is mobile and setting the appropriate width for content. This hook
 * is particularly useful for responsive layouts where component sizing and styling need to adapt based on the user's
 * device type. It also incorporates theme colors for consistent styling across different elements within the partner
 * components.
 *
 * @returns An object containing:
 * - `colors`: An object containing the current theme colors, useful for styling partner components consistently.
 * - `isMobile`: A boolean indicating whether the current device is classified as mobile (small or extra small).
 * - `width`: The calculated width for partner components, which varies based on the device type (150px for mobile devices and 300px for non-mobile devices).
 *
 * @example
 * ```ts
 * const { colors, isMobile, width } = usePartners();
 * ```
 */
export const usePartners = () => {
    const breakpoint = useScreenSize();
    const colors = useThemeColors();

    const isMobile = ['xs', 'sm'].includes(breakpoint);
    // eslint-disable-next-line @nfq/no-magic-numbers
    const width = isMobile ? 150 : 300;

    return {
        colors,
        isMobile,
        width
    };
};