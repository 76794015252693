import type {Variants} from 'framer-motion';

export const ImageViewAnimation: Variants = {
    inView: {
        scale: 1,
        transition: {
            duration: 0.5,
            ease: 'easeInOut'
        }
    },
    outOfView: {
        scale: 1.2,
        transition: {
            duration: 0.5,
            ease: 'easeInOut'
        }
    }
};