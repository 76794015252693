import {useRef} from 'react';

import {useVideoPlay} from 'UI/hooks/useVideoPlay';

/**
 * Provides functionality to play a video once and ensures it does not replay.
 * The `useHitDifferent` custom hook manages the state of video playback ensuring that the video can only be played once.
 * It utilizes a `ref` to keep track of whether the video has been played before, and another `ref` to directly manipulate the video element.
 * The hook integrates with a `useVideoPlay` hook to abstract the direct video control methods. Once the video ends, it sets the `played` state
 * to `true` to prevent future replays. This hook is ideal for scenarios where a video should only be viewed once per component lifecycle.
 *
 * @returns An object containing a `handlePlayVideo` function to initiate the video playback and a `videoRef` reference to the video element.
 *
 * @example
 * ```tsx
 * const { handlePlayVideo, videoRef } = useHitDifferent();
 * <video ref={videoRef} src="path/to/video.mp4" />
 * <button onClick={handlePlayVideo}>Play</button>
 * ```
 */
export const useHitDifferent = () => {
    const played = useRef<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const {playVideo} = useVideoPlay(videoRef);

    /**
     * Initiates video playback only if the video has not been played before.
     * This function checks if the video has been played already using a reference flag (`played`). If the video has not been played,
     * it sets an `onended` event on the video element that marks the video as played once it finishes. This prevents multiple playbacks
     * of the video during the component's lifecycle. It is designed to ensure that a video can only be played once to perhaps emphasize
     * a key visual or message without repeated interruptions.
     *
     * @example
     * ```tsx
     * const { handlePlayVideo } = useHitDifferent();
     * <button onClick={handlePlayVideo}>Play Video</button>
     * ```
     */
    const handlePlayVideo = () => {
        if (played.current) return;

        videoRef.current!.onended = () => (played.current = true);
        playVideo();
    };

    return {
        handlePlayVideo,
        videoRef
    };
};