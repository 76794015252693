/* eslint-disable import/extensions */
import xsBass from './soundexperience_bass.webp';
import smSound from './soundexperience_clear_sound.webp';
import lgMp4 from './soundExperience_lg.mp4';
import lgWebm from './soundExperience_lg.webm';
import xsMp4 from './soundExperience_xs.mp4';
import xsWebm from './soundExperience_xs.webm';


export const soundExperienceVideo = {
    lg: {
        mp4: lgMp4,
        webm: lgWebm
    },
    xs: {
        mp4: xsMp4,
        webm: xsWebm
    }
};

export const soundExperienceImageBass = {xs: xsBass};

export const soundExperienceImageSound = {xs: smSound};