import {Col, Container, media, Row, Spacer, spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {AnimatedPicture} from 'UI/components/layout/AnimatedPicture';
import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';
import {Color, H3, H6, P} from 'UI/components/layout/Text';

import {FadeInAnimation} from 'UI/animations/shared';
import {useThemeColors} from 'UI/hooks/useThemeColors';

import {DolbyAtmos} from 'Images/icons';
import {specularSound} from 'Images/specularSound';
import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a prominent feature section for the Sonos Ace headphones, focusing on the spectacular, room-filling sound
 * experience. This component visually captures the user's attention by displaying an image related to the audio quality
 * and uses typography and color to emphasize the feature of 3D Sound with Dolby Atmos. It succinctly conveys the immersive
 * audio experience that consumers can expect, highlighting industry-leading 3D audio, dynamic head tracking, and Dolby
 * rendering. This section is designed to make the technical aspects of the product accessible and engaging for the viewer,
 * enhancing the overall presentation of the Sonos Ace's capabilities on the website.
 *
 * @param props        The props passed to the component.
 * @param props.testId A unique identifier for the component instance, used primarily for testing purposes.
 * @returns A JSX element that showcases the Sonos Ace headphones' feature of spectacular, room-filling sound.
 *
 * @example
 * ```tsx
 * <FeaturesBig testId="features-big-sound" />
 * ```
 */
const FeaturesBig = ({testId}: ComponentProps) => {
    const colors = useThemeColors();

    return (
        <StyledContainer data-cy={testId} maxWidth={CONTAINER_FULLSIZE_WIDTH}>
            <Row>
                <RelativeCol offset={{xs: 0, xxl: 1}} xs={12} xxl={10}>
                    <StyledPicture alt="Spektakulärer, raumfüllender Sound" src={specularSound} />
                    <IconWrap>
                        <StyledIcon color1={colors.whiteBoxBg} />
                    </IconWrap>
                </RelativeCol>
            </Row>
            <Spacer y={{lg: 16, xs: 8}} isNotStretching />
            <InViewAnimContainer baseComponent={Row}>
                <Col lg={6} offset={{xs: 0, xxl: 1}} xs={12} xxl={5}>
                    <H6 as={motion.p} variants={FadeInAnimation}>
                        <Color $color={colors.secondaryFontColor}>
                            3D Sound mit Dolby Atmos
                        </Color>
                    </H6>
                    <Spacer y={2} isNotStretching />
                    <H3 as={motion.h2} variants={FadeInAnimation}>
                        Spektakulärer,<br />raumfüllender Sound
                    </H3>
                </Col>
                <Col lg={4} xs={12} xxl={3}>
                    <P $size="large" as={motion.p} variants={FadeInAnimation}>
                        <Color $color={colors.secondaryFontColor}>
                            Genieße branchenführendes Dolby Atmos mit dynamischem Head Tracking.
                            Erlebe hyperrealistischen Sound aus allen Richtungen
                            und höre die unterschiedlichen Elemente der Musik,
                            während du dich durch den Raum bewegst.¹
                        </Color>
                    </P>
                </Col>
            </InViewAnimContainer>
        </StyledContainer>
    );
};

FeaturesBig.displayName = 'FeaturesBig';
FeaturesBig.defaultProps = {testId: 'FeaturesBig'};

export {FeaturesBig};

const StyledContainer = styled(Container)`
    padding-block: ${spacing(14)};

    ${media('md')} {
        padding-block: ${spacing(20)};
    }
`;

const RelativeCol = styled(Col)`
    position: relative;
`;

const IconWrap = styled.div`
    align-items: center;
    border: 2px solid ${({theme}) => theme.colors.whiteBoxBg};
    border-radius: 50%;
    bottom: 2rem;
    display: flex;
    height: 4.8rem;
    justify-content: center;
    left: 2rem;
    position: absolute;
    width: 4.8rem;

    ${media('lg')} {
        bottom: 3rem;
        height: 6rem;
        left: 3rem;
        width: 6rem;
    }
`;

const StyledIcon = styled(DolbyAtmos)`
    height: 1.7rem;
    width: 1.7rem;

    ${media('lg')} {
        height: 3.2rem;
        width: 3.2rem;
    }
`;

const StyledPicture = styled(AnimatedPicture)`
    aspect-ratio: 540 / 675;
    object-fit: cover;
    width: 100%;

    ${media('sm')} {
        aspect-ratio: 780 / 780;
    }
    ${media('md')} {
        aspect-ratio: 960 / 600;
    }
    ${media('lg')} {
        aspect-ratio: 1200 / 725;
    }
    ${media('xl')} {
        aspect-ratio: 1500 / 900;
    }
`;