import {Col, Container, media, Row, Spacer, spacing, useScreenSize} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {Button} from 'UI/components/action/Button';
import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';
import {StoreCard} from 'UI/components/layout/StoreCard';
import {Color, H2, H5, P} from 'UI/components/layout/Text';

import {FadeInAnimation} from 'UI/animations/shared';
import {useThemeColors} from 'UI/hooks/useThemeColors';

import {ElectronicPartner, Euronics, Expert, MediaMarkt, Tink} from 'Images/logos';
import {CONTAINER_FULLSIZE_WIDTH, STORE_LINKS} from 'UI/utils/CONST';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a component that promotes the discovery and comparison of partner stores where users can find their favorite products.
 * The `FindStore` component showcases a list of store cards for various partners, allowing users to navigate directly to
 * the online or physical locations of these stores. This component is structured to adapt to different screen sizes and
 * uses animations to enhance visibility as the user scrolls into view.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier used for targeting the component during testing.
 * @returns A styled container that includes a title, description, and a dynamic list of store cards, each linked to a specific partner's store.
 *
 * @example
 * ```tsx
 * <FindStore testId="find-store-component" />
 * ```
 */
const FindStore = ({testId}: ComponentProps) => {
    const colors = useThemeColors();
    const screen = useScreenSize();

    return (
        <StyledContainer data-cy={testId} maxWidth={CONTAINER_FULLSIZE_WIDTH}>
            <Row>
                <InViewAnimContainer baseComponent={Col} lg={6} offset={{xs: 0, xxl: 1}} xl={5} xs={12} xxl={4}>
                    <H5 as={motion.p} variants={FadeInAnimation}>
                        <Color $color={colors.secondaryFontColor}>Vergleichen und Entdecken</Color>
                    </H5>
                    <Spacer y={6} isNotStretching />
                    <H2 as={motion.h2} variants={FadeInAnimation}>Finde deinen Lieblingsstore</H2>
                    <Spacer y={6} isNotStretching />
                    <P as={motion.p} variants={FadeInAnimation}>
                        <Color $color={colors.secondaryFontColor}>
                            Hol dir deinen idealen Sound für unterwegs. Direkt und einfach über unsere
                            Partner – Online und vor Ort.
                        </Color>
                    </P>
                    <Spacer y={{lg: 12, xs: 10}} isNotStretching />
                    <Button as="link" href="/kaufen" width={['xs', 'sm', 'md'].includes(screen) ? 'full' : 'auto'}>
                        Alle Online Shops anzeigen
                    </Button>
                </InViewAnimContainer>
                <InViewAnimContainer
                    baseComponent={Col}
                    lg={5}
                    offset={{
                        lg: 1,
                        md: 0,
                        xxl: 2
                    }}
                    xs={12}
                    xxl={4}
                >
                    <Spacer y={{lg: 0, xs: 3}} isNotStretching />
                    <P $size="small" as={motion.p} variants={FadeInAnimation}>
                        Erhältlich in den Online Shops unserer Partner:
                    </P>
                    <Spacer y={4} isNotStretching />
                    <StoreCardWrapper>
                        <StoreCard Icon={MediaMarkt} testId="store-card" url={STORE_LINKS.dark.mediaMarktSaturn} />
                        <StoreCard Icon={ElectronicPartner} testId="store-card" url={STORE_LINKS.dark.ep} />
                        <StoreCard Icon={Expert} testId="store-card" url={STORE_LINKS.dark.expert} />
                        <StoreCard Icon={Tink} testId="store-card" url={STORE_LINKS.dark.tink} />
                        <StoreCard Icon={Euronics} testId="store-card" url={STORE_LINKS.dark.euronics} />
                    </StoreCardWrapper>
                </InViewAnimContainer>
            </Row>
        </StyledContainer>
    );
};

FindStore.displayName = 'FindStore';
FindStore.defaultProps = {testId: 'FindStore'};

export {FindStore};

const StyledContainer = styled(Container)`
    padding-block: ${spacing(14)};

    ${media('md')} {
        padding-block: ${spacing(20)};
    }
`;

const StoreCardWrapper = styled.div`
    display: grid;
    gap: ${spacing(4)};
    grid-template-columns: repeat(1, 1fr);

    ${media('sm')} {
        grid-template-columns: repeat(2, 1fr);
    }
    ${media('md')} {
        grid-template-columns: repeat(3, 1fr);
    }
    ${media('lg')} {
        grid-template-columns: repeat(2, 1fr);
    }
`;