/* eslint-disable security/detect-object-injection */
/* eslint-disable max-lines-per-function */
import {Col, Container, Hidden, media, Row, Spacer} from '@nfq/react-grid';
import {AnimatePresence, m as motion} from 'framer-motion';
import styled from 'styled-components';

import {Picture} from 'UI/components/layout/Picture';
import {Color, P} from 'UI/components/layout/Text';
import {Video} from 'UI/components/layout/Video';

import {highlighterTransition} from 'UI/animations/uniqueDesign';
import {
    uniqueDesignImages,
    uniqueDesignVideo1,
    uniqueDesignVideo2,
    uniqueDesignVideo3
} from 'UI/assets/videos/uniqueDesign';

import {useUniqueDesign} from './useUniqueDesign';
import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';


/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * A React component representing a unique design feature composed of multiple details, each including a video element and associated content.
 * This component integrates the `useUniqueDesign` hook to manage the interaction and state of the design feature, providing dynamic control over
 * the active detail and video playback. It renders a wrapper with a container and two columns: one for images and one for assets. The assets column
 * includes a slider container with multiple detail wrappers, each containing a video element and related content. The component utilizes motion
 * animations for transitions between active and inactive details, enhancing the visual experience and user engagement.
 *
 * @param props        The component props.
 * @param props.testId The test ID for quality assurance and testing purposes.
 * @returns JSX element representing the unique design feature component.
 *
 * @example
 * ```tsx
 * <UniqueDesign testId="unique-design" />
 * ```
 */
const UniqueDesign = ({testId}: ComponentProps) => {
    const {
        activeOpacity,
        colors,
        currentActiveDetail,
        firstDetail,
        inactiveOpacity,
        secondDetail,
        setSliderContainerInView,
        sliderContainerRef,
        slideRef,
        thirdDetail,
        videoRefs,
        viewport,
        viewportSetActive
    } = useUniqueDesign();

    return (
        <Wrapper data-cy={testId}>
            <Container maxWidth={CONTAINER_FULLSIZE_WIDTH}>
                <ScrollContainer>
                    <Hidden sm xs>
                        <ImageColumn md={7} offset={{xl: 1}} xl={6} xs={12}>
                            <ImageWrapper>
                                <StyledImage alt="" src={uniqueDesignImages} />
                                <AnimatePresence mode="sync" initial>
                                    {currentActiveDetail === firstDetail && (
                                        <Highlighter0
                                            key="highlighter0"
                                            animate="animate"
                                            exit="exit"
                                            initial="initial"
                                            variants={highlighterTransition}
                                        />
                                    )}
                                    {currentActiveDetail === secondDetail && (
                                        <Highlighter1
                                            key="highlighter1"
                                            animate="animate"
                                            exit="exit"
                                            initial="initial"
                                            variants={highlighterTransition}
                                        />
                                    )}
                                    {currentActiveDetail === thirdDetail && (
                                        <Highlighter2
                                            key="highlighter2"
                                            animate="animate"
                                            exit="exit"
                                            initial="initial"
                                            variants={highlighterTransition}
                                        />
                                    )}
                                </AnimatePresence>
                            </ImageWrapper>
                        </ImageColumn>
                    </Hidden>
                    <AssetColumn ref={slideRef} md={5} xl={5} xs={12}>
                        <SliderContainer
                            ref={sliderContainerRef}
                            onViewportEnter={() => setSliderContainerInView(true)}
                            onViewportLeave={() => setSliderContainerInView(false)}
                        >
                            <DetailWrapper
                                animate={
                                    {opacity: currentActiveDetail === firstDetail ? activeOpacity : inactiveOpacity}
                                }
                                viewport={viewport}
                                onViewportEnter={() => viewportSetActive(firstDetail)}
                            >
                                <StyledVideo
                                    ref={videoRefs[firstDetail]}
                                    src={uniqueDesignVideo1}
                                    loop
                                    muted
                                    playsInline
                                />
                                <Spacer y={6} />
                                <P>
                                    <Color $color={colors.primaryFontColorLight}>Einstellbares Edelstahlband</Color>
                                </P>
                            </DetailWrapper>
                            <Hidden sm xs>
                                <Spacer y={16} />
                            </Hidden>
                            <DetailWrapper
                                animate={
                                    {opacity: currentActiveDetail === secondDetail ? activeOpacity : inactiveOpacity}
                                }
                                viewport={viewport}
                                onViewportEnter={() => viewportSetActive(secondDetail)}
                            >
                                <StyledVideo
                                    ref={videoRefs[secondDetail]}
                                    src={uniqueDesignVideo2}
                                    loop
                                    muted
                                    playsInline
                                />
                                <Spacer y={6} />
                                <P>
                                    <Color $color={colors.primaryFontColorLight}>Positionsbestimmende Mikrofone</Color>
                                </P>
                            </DetailWrapper>
                            <Hidden sm xs>
                                <Spacer y={16} />
                            </Hidden>
                            <DetailWrapper
                                animate={
                                    {opacity: currentActiveDetail === thirdDetail ? activeOpacity : inactiveOpacity}
                                }
                                viewport={viewport}
                                onViewportEnter={() => viewportSetActive(thirdDetail)}
                            >
                                <StyledVideo
                                    ref={videoRefs[thirdDetail]}
                                    src={uniqueDesignVideo3}
                                    loop
                                    muted
                                    playsInline
                                />
                                <Spacer y={6} />
                                <P>
                                    <Color $color={colors.primaryFontColorLight}>Dynamische Treiber</Color>
                                </P>
                            </DetailWrapper>
                        </SliderContainer>
                        <Spacer y={{md: 16, xs: 14}} />
                    </AssetColumn>
                </ScrollContainer>
            </Container>
        </Wrapper>
    );
};

UniqueDesign.displayName = 'UniqueDesign';
UniqueDesign.defaultProps = {testId: 'UniqueDesign'};

export {UniqueDesign};

const Wrapper = styled.div`
    overflow: hidden;

    ${media('md')} {
        overflow: visible;
    }
`;

const ScrollContainer = styled(Row)`
    ${media('md')} {
        position: relative;
    }
`;

const ImageColumn = styled(Col)`
    align-items: flex-start;
    align-self: flex-start;
    container-type: inline-size;
    display: flex;
    justify-content: center;
    position: sticky;
    top: calc(50% - (50cqh / 1.5));
`;

const ImageWrapper = styled.div`
    position: relative;
    width: fit-content;
`;

const StyledImage = styled(Picture)`
    max-height: 80dvh;
    width: 100%;
`;

const AssetColumn = styled(Col)`
    display: flex;
`;

const SliderContainer = styled(motion.div)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2.4rem;
    max-width: unset;

    ${media('md')} {
        display: block;
    }
`;

const DetailWrapper = styled(motion.div)`
    flex: 0 0 calc(100dvw - 8rem);
    height: auto;
    max-width: 73rem;

    ${media('md')} {
        width: 100%;
    }
`;

const Highlighter0 = styled(motion.div)`
    aspect-ratio: 1 / 1;
    background-color: ${({theme}) => theme.colors.hightlightPointerColor};
    border: 0.2rem solid ${({theme}) => theme.colors.primaryFontColorLight};
    border-radius: 50%;
    height: auto;
    left: 16%;
    position: absolute;
    top: 53%;
    width: clamp(3rem, 10%, 8rem);
`;

const Highlighter1 = styled(Highlighter0)`
    left: 31%;
    top: 64%;
`;

const Highlighter2 = styled(Highlighter0)`
    left: 56%;
    top: 58%;
`;

const StyledVideo = styled(Video)`
    width: 100%;
`;