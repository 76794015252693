
import {Col, Container, media, Row, Spacer, spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {Button} from 'UI/components/action/Button';
import {Slider} from 'UI/components/action/Slider/Slider';
import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';
import {Color, H3, H5} from 'UI/components/layout/Text';

import {FadeInAnimation} from 'UI/animations/shared';

import {usePartners} from './usePartners';
import {ElectronicPartner, Euronics, Expert, MediaMarkt, Tink} from 'Images/logos';
import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a component showcasing various partner stores where products are available. This component utilizes the `usePartners` hook
 * to dynamically adjust its layout and content based on the device's screen size. It provides an engaging user interface that includes
 * a header section with custom text colors and a button that links to a page where users can discover and compare partner stores. The
 * `Slider` component within displays logos of partner stores, scaling them responsively based on the current device width. This component
 * is designed to be highly responsive, making it suitable for a variety of devices from mobiles to desktops.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for the component, useful for testing purposes.
 * @returns A React component that presents information about partner stores and provides links to explore these stores further.
 *
 * @example
 * ```tsx
 * <Partners testId="partners-section" />
 * ```
 */
const Partners = ({testId}: ComponentProps) => {
    const {colors, isMobile, width} = usePartners();

    return (
        <OverflowContainer data-cy={testId}>
            <Container maxWidth={CONTAINER_FULLSIZE_WIDTH}>
                <Row align="center">
                    <InViewAnimContainer baseComponent={Col} lg={7} offset={{xs: 0, xxl: 1}} xs={12} xxl={6}>
                        <H5 as={motion.p} variants={FadeInAnimation}>
                            <Color $color={colors.secondaryFontColor}>
                                Entdecken und Vergleichen
                            </Color>
                        </H5>
                        <Spacer y={6} />
                        <H3 as={motion.h2} variants={FadeInAnimation}>
                            Jetzt verfügbar bei<br />unseren Premium Partnern
                        </H3>
                        <Spacer y={{lg: 0, xs: 4}} isNotStretching />
                    </InViewAnimContainer>
                    <Col
                        align={{
                            lg: 'flex-end',
                            xs: 'flex-start'
                        }}
                        lg={5}
                        xs={12}
                        xxl={4}
                    >
                        <Button
                            as="link"
                            href="/kaufen"
                            width={isMobile ? 'full' : 'auto'}
                        >
                            Partner Stores entdecken
                        </Button>
                    </Col>
                </Row>
                <Spacer y={{
                    lg: 15,
                    xs: 8
                }}
                />
                <Slider data-cy={testId}>
                    <ElectronicPartner width={width} />
                    <Euronics width={width} />
                    <Expert width={width} />
                    <MediaMarkt width={width} />
                    <Tink width={width} />
                </Slider>
            </Container>

        </OverflowContainer>
    );
};

Partners.displayName = 'Partners';
Partners.defaultProps = {testId: 'Partners'};

export {Partners};

const OverflowContainer = styled.div`
    overflow: hidden;
    padding-block: ${spacing(14)};
    width: 100%;

    ${media('md')} {
        padding-block: ${spacing(20)};
    }
`;