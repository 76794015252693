import {Col, Container, media, Row} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';
import {H1} from 'UI/components/layout/Text';

import {FadeInFitsDifferentAnimation, FadeInHitsDifferentAnimation} from 'UI/animations/shared';

import {HitDifferentVideo} from './HitDifferentVideo';
import {useHitDifferent} from './useHitDifferent';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a full-size video component with dynamic scaling and translation effects based on the user's scroll position.
 * This component utilizes the `useHitDifferent` hook to control video playback and apply real-time visual effects,
 * enhancing user interaction and engagement. The video automatically plays when entering the viewport and pauses upon
 * leaving, creating an immersive experience. Text content is animated alongside the video, moving horizontally to further
 * captivate the viewer. The component is designed to be responsive, with video source options and scaling factors that adjust
 * based on the viewing environment. This component is ideal for showcasing products, services, or any content where visual impact
 * is paramount.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for the component, useful for testing purposes.
 * @returns A React component that displays a full-size, responsive video with accompanying animated text, controlled by scroll position.
 *
 * @example
 * ```tsx
 * <HitDifferent testId="fullsize-video-component" />
 * ```
 */
const HitDifferent = ({testId}: ComponentProps) => {
    const {handlePlayVideo, videoRef} = useHitDifferent();

    return (
        <InViewAnimContainer
            baseComponent={VideoContainer}
            testId={testId}
            onViewportEnter={handlePlayVideo}
        >
            <AbsoluteContainer isFluid>
                <StyledRow
                    align={{lg: 'center', xs: 'center'}}
                    direction={{lg: 'row', xs: 'column'}}
                    hasNoWrap={['lg', 'xl', 'xxl']}
                    justify={{lg: 'space-between', xs: 'flex-start'}}
                >
                    <StyledCol align="flex-start">
                        <StyledH1 as={motion.h3} variants={FadeInHitsDifferentAnimation}>Hits different.</StyledH1>
                    </StyledCol>
                    <SaveZone>&nbsp;</SaveZone>
                    <StyledCol align={{lg: 'flex-end', xs: 'flex-start'}}>
                        <StyledH1 as={motion.h3} variants={FadeInFitsDifferentAnimation}>Fits different.</StyledH1>
                    </StyledCol>
                </StyledRow>
            </AbsoluteContainer>
            <HitDifferentVideo videoRef={videoRef} />
            <AbsoluteGradient />
        </InViewAnimContainer>
    );
};

HitDifferent.displayName = 'HitDifferent';
HitDifferent.defaultProps = {testId: 'HitDifferent'};

export {HitDifferent};

const VideoContainer = styled.div`
    background-color: ${({theme}) => theme.colors.linenBoxBg};
    overflow: hidden;
    position: relative;
`;

const AbsoluteContainer = styled(Container)`
    align-items: center;
    background-color: transparent;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
`;

const AbsoluteGradient = styled.div`
    background: ${({theme}) => `linear-gradient(to bottom, transparent, ${theme.colors.linenBoxBg})`};
    bottom: 0;
    height: 15%;
    position: absolute;
    width: 100%;
    z-index: 1;
`;

const StyledRow = styled(Row)`
    --font-size: clamp(4.7rem, 5cqw, 8rem);
    container-type: inline-size;
    height: 60%;
    row-gap: 0px;

    ${media('lg')} {
        row-gap: ${({theme}) => theme.nfqgrid.columnGap?.lg}px;
    }
`;

const SaveZone = styled.div`
    display: flex;
    flex: 1 0 70%;
    height: 70%;
    width: auto;

    ${media('lg')} {
        flex: 1 0 30%;
        height: auto;
        width: 30%;
    }
`;

const StyledCol = styled(Col)`
    min-width: 0;
`;

const StyledH1 = styled(H1)`
    ${media('lg')} {
        font-size: var(--font-size);
    }
`;