import {useCallback, useEffect, useRef, useState} from 'react';

import {useConfig, useScreenSize} from '@nfq/react-grid';
import {useScroll, useSpring, useTransform} from 'framer-motion';

import {useSlider} from 'UI/components/action/Slider/useSlider';

import {useThemeColors} from 'UI/hooks/useThemeColors';

import type {MotionValue} from 'framer-motion';

interface textStyleType {
    opacity: MotionValue<number> | number;
    y: MotionValue<number> | number;
}

/**
 * A comprehensive custom React hook that synchronizes video playback with scroll behavior, adjusts video content based on user interaction,
 * and applies dynamic text animations based on the user's scroll position. This hook is designed for interactive multimedia experiences, particularly
 * useful on devices of all sizes. It incorporates responsive behavior to differentiate interaction between mobile and non-mobile devices and utilizes
 * `useSlider` to handle component visibility and interactivity through sliding mechanisms.
 *
 * The hook conditions the video's playback to the scroll position for non-mobile devices and to the slide index for mobile devices when the component is in the viewport.
 * Additionally, it employs complex logic to smoothly transition video playback to specific timestamps, ensuring that the video aligns with the user's current focus area.
 *
 * @returns An object containing:
 *- `defaultText`: A style object for text when no animations are applied.
 *- `firstText` and `secondText`: Style objects for the first and second text elements that include opacity and vertical position for animation.
 *- `isMobile`: A boolean indicating if the device is a mobile device (true) or not (false).
 *- `ref`: A reference to the scrollable container, used to link the scroll monitoring functionality.
 *- `setIsInViewport`: A function to update the visibility state of the slider container.
 *- `slideRef`: A reference provided by `useSlider` hook for slider interaction.
 *- `videoRef`: A reference to the video element, allowing for direct manipulation of the video's playback.
 *
 * This hook is ideal for creating engaging and interactive sections on webpages that combine video, text, and scroll interactions to deliver a narrative or detailed information dynamically.
 *
 * @example
 * ```ts
 * const {
 *   defaultText,
 *   firstText,
 *   isMobile,
 *   ref,
 *   secondText,
 *   setIsInViewport,
 *   slideRef,
 *   videoRef,
 * } = useSoundExperience();
 * ```
 */
export const useSoundExperience = () => {
    const colors = useThemeColors();
    const ref = useRef(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const {scrollYProgress} = useScroll({
        offset: ['-5% start', 'end end'],
        smooth: 0.05,
        target: ref
    });
    const breakpoint = useScreenSize();
    const isMobile = ['xs', 'sm'].includes(breakpoint);
    const config = useConfig();

    const {breakpoints} = config;
    const [isInViewport, setIsInViewport] = useState(false);

    const {selectedIndex, slideRef} = useSlider({
        align: 'start',
        breakpoints: {[`(min-width: ${breakpoints.md}px)`]: {active: false}}
    });

    const smoothVelocity: MotionValue<number> = useSpring(scrollYProgress, {
        bounce: 0,
        damping: 60,
        mass: 0.5,
        stiffness: 480
    });

    const firstText: textStyleType = {
        opacity: useTransform(smoothVelocity, [0.2, 0.23, 0.5, 0.53], [0, 1, 1, 0]),
        y: useTransform(smoothVelocity, [0.2, 0.23, 0.5, 0.53], [25, 0, 0, 0])
    };

    const secondText: textStyleType = {
        opacity: useTransform(smoothVelocity, [0.62, 0.65, 0.85, 0.88], [0, 1, 1, 0]),
        y: useTransform(smoothVelocity, [0.62, 0.65, 0.85, 0.88], [25, 0, 0, 0])
    };

    const videoSequences = [5, 11.3];

    const defaultText = {
        opacity: 1,
        y: 0
    };

    const currentAnimationFrame = useRef<number | null>(null);

    const smoothSeek = useCallback((targetSeconds: number) => {
        const video = videoRef.current;

        if (!video) return;
        if (targetSeconds > video.duration) return;

        if (currentAnimationFrame.current) {
            cancelAnimationFrame(currentAnimationFrame.current);
        }

        // eslint-disable-next-line @nfq/no-magic-numbers
        const rate = targetSeconds > video.currentTime ? 0.04 : -0.04;
        const tolerance = 0.5;

        /**
         * This function `updateCurrentTime` is designed to smoothly update the current playback time of a video to a specified target time.
         * It continuously adjusts the video's `currentTime` property in small increments (defined by `rate`) until it closely matches the target time (`targetSeconds`).
         * The function uses `requestAnimationFrame` to ensure that each increment occurs at a time that aligns with the browser's repaints, providing a smooth transition.
         * It incorporates a `tolerance` level to determine how close the `currentTime` must be to the `targetSeconds` before the adjustment process is considered complete.
         * Once the target is achieved within the specified tolerance, it terminates the updates by canceling the ongoing animation frame request, thus freeing up resources.
         *
         * @example
         * ```ts
         * // Continuously updates the video's current time until it matches the target seconds within a defined tolerance.
         * updateCurrentTime();
         * ```
         */
        const updateCurrentTime = () => {
            if (Math.abs(video.currentTime - targetSeconds) > tolerance) {
                video.currentTime += rate;
                currentAnimationFrame.current = requestAnimationFrame(updateCurrentTime);
            } else {
                video.currentTime = targetSeconds;
                if (currentAnimationFrame.current) {
                    cancelAnimationFrame(currentAnimationFrame.current);
                    currentAnimationFrame.current = null;
                }
            }
        };

        currentAnimationFrame.current = requestAnimationFrame(updateCurrentTime);
    }, [videoRef]);


    useEffect(() => {
        const video = videoRef.current;

        if (!isMobile || !video || !isInViewport) {
            return;
        }

        // eslint-disable-next-line security/detect-object-injection
        smoothSeek(videoSequences[selectedIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex, isInViewport]);


    useEffect(() => {
        const video = videoRef.current;

        if (!video || isMobile) {
            smoothVelocity.clearListeners();

            return;
        }

        smoothVelocity.on('change', (y: number) => {
            const {duration} = video;
            const time = y * duration;

            video.currentTime = Number.isFinite(time) ? time : 0;
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRef, isMobile]);

    return {
        colors,
        defaultText,
        firstText,
        isMobile,
        ref,
        secondText,
        setIsInViewport,
        slideRef,
        videoRef
    };
};