import type {Variants} from 'framer-motion';

export const highlighterTransition: Variants = {
    animate: {
        scale: 1,
        transition: {
            bounce: 0.5,
            type: 'spring'
        }
    },
    exit: {scale: 0},
    initial: {scale: 0}
};