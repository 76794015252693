
import type {RefObject} from 'react';

import {Video} from 'UI/components/layout/Video';

import {hitDifferent} from 'Videos/hitDifferent';

import {useHitDifferentVideo} from './useHitDifferentVideo';

/**
 * Defines the structure for component properties where a video element and a unique test identifier are involved.
 * This interface is particularly useful for components that interact with video elements and need to be testable in a predictable manner.
 * It ensures that every component implementing this interface will receive a necessary HTMLVideoElement reference and a unique identifier for testing purposes.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
    /**
     * The `videoRef` property is a React ref that points to an HTMLVideoElement.
     * This ref is used to directly interact with the video DOM element, allowing for operations like play, pause, or seeking within the video.
     * It provides a mechanism to programmatically control video behavior from within React components.
     */
    videoRef: RefObject<HTMLVideoElement>;
}

/**
 * Renders a video component that adapts based on the screen size. This component is designed to
 * use a custom hook to determine the appropriate video settings and size adjustments for optimal viewing.
 * The `videoRef` allows for external control and manipulation of the video element, such as play, pause, or seek operations.
 *
 * @param props          The component props.
 * @param props.videoRef A React ref object linked to the video element for direct manipulation.
 * @param props.testId   A unique identifier for the component, useful for testing purposes.
 * @returns A JSX.Element that renders a video with properties dynamically set based on the current screen size.
 *
 * @example
 * ```tsx
 * const videoRef = useRef<HTMLVideoElement>(null);
 * <HitDifferentVideo videoRef={videoRef} />
 * ```
 */
const HitDifferentVideo = ({testId, videoRef}: ComponentProps) => {
    const {screenSize} = useHitDifferentVideo(videoRef);

    return (
        <Video
            key={`hitDifferent-${screenSize}`}
            ref={videoRef}
            src={hitDifferent}
            testId={testId}
            width="100%"
            muted
            playsInline
        />
    );
};

HitDifferentVideo.displayName = 'HitDifferentVideo';
HitDifferentVideo.defaultProps = {testId: 'HitDifferentVideo'};

export {HitDifferentVideo};