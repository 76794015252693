/* eslint-disable max-lines-per-function */
import {Col, Container, Hidden, media, Row, Spacer, spacing, Visible} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {Picture} from 'UI/components/layout/Picture';
import {Color, H4, P} from 'UI/components/layout/Text';
import {Video} from 'UI/components/layout/Video';

import {
    soundExperienceImageSound,
    soundExperienceVideo
} from 'UI/assets/videos/soundExperience';

import {useSoundExperience} from './useSoundExperience';
import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a component designed to provide an interactive audiovisual experience that aligns sound characteristics with user scroll interactions.
 * The `SoundExperience` component uses the `useSoundExperience` hook to manage video playback and dynamic text visibility based on scroll position.
 * Text content related to the sound features, such as "incredibly clear" and "superior bass," is displayed in context with the video content, enhancing
 * the user's understanding of the product's audio capabilities. The component toggles text visibility and applies style transformations depending on
 * whether the device is mobile or not, ensuring optimal viewing across all devices. This setup allows for a deep, engaging exploration of audio features
 * through text and video, synchronized to user interactions with the webpage.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for the component, useful for testing purposes.
 * @returns A React component that visually and interactively demonstrates the sound experience of a product, tailored to user engagement through scrolling.
 *
 * @example
 * ```tsx
 * <SoundExperience testId="sound-experience-section" />
 * ```
 */
const SoundExperience = ({testId}: ComponentProps) => {
    const {
        colors,
        defaultText,
        firstText,
        isMobile,
        ref,
        secondText,
        setIsInViewport,
        slideRef,
        videoRef
    } = useSoundExperience();

    return (
        <Wrapper ref={ref} data-cy={testId}>
            <Spacer y={{md: 0, xs: 6}} />
            <GradientDiv $position="top" />
            <FloatingWrapper onViewportEnter={() => setIsInViewport(true)}>
                <Hidden sm xs>
                    <StyledVideo ref={videoRef} src={soundExperienceVideo} />
                </Hidden>
                <Visible sm xs>
                    <>
                        <StyledPicture
                            alt="Unglaublich klar"
                            src={soundExperienceImageSound}
                        />
                        <Spacer y={6} />
                    </>
                </Visible>
                <SliderWrapperOuter ref={slideRef}>
                    <SliderWrapper>
                        <ContentWrapper key={`contentWrapper1_${isMobile ? 'mobile' : 'desktop'}`} style={isMobile ? defaultText : firstText}>
                            <StyledContainer maxWidth={CONTAINER_FULLSIZE_WIDTH}>
                                <Row>
                                    <Col lg={4} md={6} offset={{xl: 1, xxl: 2}} xs={12}>
                                        <H4>
                                            Unglaublich klar
                                        </H4>
                                        <Spacer y={6} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3} md={5} offset={{xl: 1, xxl: 2}} xs={12}>
                                        <P>
                                            <Color $color={colors.secondaryFontColor}>
                                                Der dynamische Treiber ist so positioniert, dass er ein vollständig
                                                ausgewogenes Soundprofil und eine verzerrungsfreie Wiedergabe liefert,
                                                und jede Frequenz mit unglaublicher Präzision und Genauigkeit
                                                wiedergibt.
                                            </Color>
                                        </P>
                                    </Col>
                                </Row>
                            </StyledContainer>
                        </ContentWrapper>
                        <ContentWrapper key={`contentWrapper2_${isMobile ? 'mobile' : 'desktop'}`} style={isMobile ? defaultText : secondText}>
                            <StyledContainer maxWidth={CONTAINER_FULLSIZE_WIDTH}>
                                <Row>
                                    <Col lg={4} md={6} offset={{md: 6}} xs={12}>
                                        <H4>
                                            Überragender Bass
                                        </H4>
                                        <Spacer y={6} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3} md={5} offset={{md: 6}} xs={12}>
                                        <P>
                                            <Color $color={colors.secondaryFontColor}>
                                                Die portierte akustische Architektur verbessert die Basswiedergabe
                                                jedes Treibers, um die ganze Fülle und Tiefe deiner Musik
                                                und deines Entertainments zu enthüllen.
                                            </Color>
                                        </P>
                                    </Col>
                                </Row>
                            </StyledContainer>
                        </ContentWrapper>
                    </SliderWrapper>
                    <Visible sm xs>
                        <GradientDiv $position="bottom" />
                    </Visible>
                </SliderWrapperOuter>
            </FloatingWrapper>
            <Hidden sm xs>
                <GradientDiv $position="bottom" />
            </Hidden>
        </Wrapper>
    );
};

SoundExperience.displayName = 'SoundExperience';
SoundExperience.defaultProps = {testId: 'SoundExperience'};

export {SoundExperience};

const Wrapper = styled.div`
    position: relative;

    ${media('md')} {
        height: 650dvh;
    }
`;

const ContentWrapper = styled(motion.div)`
    max-width: 73rem;
    min-width: calc(100dvw - 8rem);

    ${media('md')} {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;
    }
`;

const StyledContainer = styled(Container)``;

const SliderWrapperOuter = styled.div`
    position: relative;

    ${media('md')} {
        position: unset;
    }
`;

const SliderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: unset;

    ${media('md')} {
        display: block;
    }
`;

const FloatingWrapper = styled(motion.div)`
    padding-bottom: ${spacing(14)};

    ${media('md')} {
        height: calc(100dvh - 7.3rem);
        padding-bottom: 0px;
        position: sticky;
        top: 7.3rem;
        width: 100%;
    }
`;

const StyledVideo = styled(Video)`
    width: 100%;

    ${media('md')} {
        height: 100%;
        object-fit: cover;
        top: 7.3rem;
        z-index: 0;
    }
`;

interface GradientDivProps {
    $position: 'bottom' | 'top';
}

const GradientDiv = styled.div<GradientDivProps>`
    ${media('md')} {
        background: linear-gradient(to ${({$position}) => ($position === 'top' ? 'bottom' : 'top')}, ${({theme}) => theme.colors.videoBackBg}, transparent);
        height: 15dvh;
        position: absolute;
        width: 100%;
        z-index: 1;
        ${({$position}) => `${$position}: 0px`};
    }
`;

const StyledPicture = styled(Picture)`
    height: auto;
    width: 100%;
`;