/* eslint-disable max-len */
import React from 'react';

interface BluetoothProps {
    /**
     * A string representing the CSS class to be applied to the BluetoothIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the BluetoothIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the BluetoothIcon element.
     */
    width?: number | string;
}

/**
 * The `Bluetooth` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `BluetoothProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the BluetoothIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the BluetoothIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the BluetoothIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const BluetoothComponent = <Bluetooth className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Bluetooth = React.forwardRef<SVGSVGElement, BluetoothProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 80 80"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M65.02 24.23 33.1 0v24.58l-13.54-12.1L14 18.7l19.1 17.08v7.45L14 60.3l5.57 6.22 13.54-12.1V80L65 55.77 45.16 40zm-23.56-7.4 9.94 7.55-9.8 7.78-.13-.12v-15.2zm0 46.33V47.73l9.94 7.88z" fill={color1} />
        </svg>
    );
});

Bluetooth.displayName = 'Bluetooth';
Bluetooth.defaultProps = {
    className: '',
    color1: '#212325',
    height: 80,
    testId: 'Bluetooth',
    width: 80
};