import {Col, Container, media, Row, Spacer, spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';
import {Picture} from 'UI/components/layout/Picture';
import {Color, H3, P} from 'UI/components/layout/Text';

import {useThemeColors} from 'UI/hooks/useThemeColors';

import {protectionImage} from 'Images/protection';
import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a visually engaging component that highlights the protective features of a product's casing.
 * This component uses `useThemeColors` to apply thematic colors, enhancing the visual hierarchy and readability of the text content.
 * It is designed to convey the style and durability of the product's case. The layout centers around text that describes these features, supported by a stylized image that
 * visually represents the product's case. The `InViewAnimContainer` ensures that elements animate into view, providing a dynamic
 * user experience as they scroll through the page. This setup is ideal for product pages where highlighting specific features
 * in an aesthetically pleasing manner can enhance consumer perception and interest.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for the component, useful for testing purposes.
 * @returns A React component that displays a centered text block and an image, emphasizing the protective qualities of the product's case.
 *
 * @example
 * ```tsx
 * <Protected testId="protected-section" />
 * ```
 */
const Protected = ({testId}: ComponentProps) => {
    const colors = useThemeColors();

    return (
        <StyledDiv data-cy={testId}>
            <InViewAnimContainer baseComponent={Wrapper} stagger={0.1}>
                <Spacer y={15} />
                <Row justify="center">
                    <Col lg={4} md={6} xs={12}>
                        <H3 $align="center" as={motion.p}>
                            <Color $color={colors.primaryFontColorLight}>
                                Stilsicher geschützt
                            </Color>
                        </H3>
                        <Spacer y={6} />
                        <P $align="center" $size="large" as={motion.p}>
                            <Color $color={colors.secondaryFontColor}>
                                Die extrem schmale und robuste Hülle aus recycelten Materialien
                                verfügt über eine magnetische, abnehmbare Kabeltasche.
                            </Color>
                        </P>
                    </Col>
                </Row>
                <Spacer y={11} />
            </InViewAnimContainer>
            <InViewAnimContainer baseComponent={Wrapper} maxWidth={CONTAINER_FULLSIZE_WIDTH} stagger={0.1}>
                <StyledPicture alt="Case" src={protectionImage} />
            </InViewAnimContainer>
        </StyledDiv>
    );
};

Protected.displayName = 'Protected';
Protected.defaultProps = {testId: 'Protected'};

export {Protected};

const Wrapper = styled(Container)`
    picture {
        display: block;
        font-size: 0;
        line-height: 0;
    }
`;

const StyledPicture = styled(Picture)`
    aspect-ratio: 540 / 408;
    width: 100%;

    ${media('sm')} {
        aspect-ratio: 780 / 600;
    }
    ${media('md')} {
        aspect-ratio: 1000 / 425;
    }
    ${media('lg')} {
        aspect-ratio: 1200 / 510;
    }
    ${media('xl')} {
        aspect-ratio: 1600 / 680;
    }
`;

const StyledDiv = styled.div`
    padding-block-end: ${spacing(24)};

    ${media('md')} {
        padding-block-end: 0;
    }
`;