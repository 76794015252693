/* eslint-disable import/extensions */
import video1LGMp4 from './uniqueDesign_v1_lg.mp4';
import video1LGWemb from './uniqueDesign_v1_lg.webm';
import video1XSMp4 from './uniqueDesign_v1_xs.mp4';
import video1XSWemb from './uniqueDesign_v1_xs.webm';
import video2LGMp4 from './uniqueDesign_v2_lg.mp4';
import video2LGWemb from './uniqueDesign_v2_lg.webm';
import video2XSMp4 from './uniqueDesign_v2_xs.mp4';
import video2XSWemb from './uniqueDesign_v2_xs.webm';
import video3LGMp4 from './uniqueDesign_v3_lg.mp4';
import video3LGWemb from './uniqueDesign_v3_lg.webm';
import video3XSMp4 from './uniqueDesign_v3_xs.mp4';
import video3XSWemb from './uniqueDesign_v3_xs.webm';
import xs from './uniqueDesign_xs.webp';

export const uniqueDesignImages = {xs};

export const uniqueDesignVideo1 = {
    lg: {
        mp4: video1LGMp4,
        webm: video1LGWemb
    },
    xs: {
        mp4: video1XSMp4,
        webm: video1XSWemb
    }
};

export const uniqueDesignVideo2 = {
    lg: {
        mp4: video2LGMp4,
        webm: video2LGWemb
    },
    xs: {
        mp4: video2XSMp4,
        webm: video2XSWemb
    }
};
export const uniqueDesignVideo3 = {
    lg: {
        mp4: video3LGMp4,
        webm: video3LGWemb
    },
    xs: {
        mp4: video3XSMp4,
        webm: video3XSWemb
    }
};