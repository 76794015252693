import {media, spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {FadeInAnimation} from 'UI/animations/shared';

import type {ElectronicPartner, Euronics, Expert, MediaMarkt, Tink} from 'Images/logos';
import {chooseTheme} from 'UI/utils/helpers';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The class name to apply to the component.
     */
    className?: string;
    /**
     * The Icon component used to display the retailer's logo.
     */
    Icon: typeof ElectronicPartner | typeof Euronics | typeof Expert | typeof MediaMarkt | typeof Tink;
    /**
     * Optional flag to enable theme reactivity, allowing the component to adjust its appearance based on the current theme.
     */
    // eslint-disable-next-line react/boolean-prop-naming
    reactsToTheme?: boolean;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
    /**
     * The URL to the retailer's online store, which will be linked via a button in the component.
     */
    url: string;
}

/**
 * Represents a store card component used to display an icon representing a specific retailer and a link button that
 * redirects to the retailer's online store. The `StoreCard` component takes a component for the retailer's icon, a
 * unique test identifier for testing purposes, and a URL for the retailer's online store. It uses screen size information
 * to adjust the layout and button size dynamically. This component is useful for creating a uniform display of various
 * retailer options where users can quickly access their preferred store online.
 *
 * @param props               The component props.
 * @param props.Icon          The Icon component used to display the retailer's logo.
 * @param props.testId        A unique identifier used for targeting the component during testing.
 * @param props.url           The URL to the retailer's online store, which will be linked via a button in the component.
 * @param props.className     The class name to apply to the component.
 * @param props.reactsToTheme Optional flag to enable theme reactivity, allowing the component to adjust its appearance based on the current theme.
 * @returns A `Wrapper` component containing the retailer's icon and a button linking to the online store.
 *
 * @example
 * ```tsx
 * <StoreCard Icon={Euronics} testId="euronics-store" url="https://www.euronics.de" />
 * ```
 */
const StoreCard = ({className, Icon, reactsToTheme, testId, url}: ComponentProps) => (
    <Wrapper
        $reactsToTheme={reactsToTheme}
        className={className}
        data-cy={testId}
        href={url}
        target="_blank"
        variants={FadeInAnimation}
    >
        <Icon height={60} isReactingToTheme={reactsToTheme} width={150} />
    </Wrapper>
);

StoreCard.displayName = 'StoreCard';
StoreCard.defaultProps = {
    className: '',
    testId: 'StoreCard'
};

export {StoreCard};

interface WrapperProps {
    $reactsToTheme?: boolean;
}

const Wrapper = styled(motion.a)<WrapperProps>`
    align-items: center;
    background-color: ${chooseTheme('cardBackground')};
    display: flex;
    flex-direction: column;
    padding-block: ${spacing(6)};
    padding-inline: ${spacing(6)};
    transition: background-color 0.2s ease-in-out;
    will-change: background-color;

    &:hover {
        background-color: ${chooseTheme('cardHover')};
    }

    ${media('sm')} {
        padding-block: ${spacing(8)};
    }

    svg {
        max-width: 100%;
    }
`;