import {Col, Container, media, Row, spacing, Visible} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';
import {Color, H3, P} from 'UI/components/layout/Text';

import {FadeInAnimation} from 'UI/animations/shared';
import {useThemeColors} from 'UI/hooks/useThemeColors';

import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a section dedicated to describing the barely perceptible wearing sensation of the Sonos Ace headphones.
 * This component emphasizes the design and materials used in the Sonos Ace to ensure comfort during prolonged use.
 * It features a headline to attract attention to the comfort aspect and uses descriptive text to elaborate on the materials
 * like the soft foam ear cushions and the lightweight frame. The choice of colors from `useThemeColors` and layout
 * adjustments for different screen sizes provided by the `Col` and `Visible` components ensure that the information
 * is presented in an aesthetically pleasing and easy-to-read manner. This component helps convey to potential customers
 * the exceptional comfort that sets the Sonos Ace apart from other headphones.
 *
 * @param props        The props passed to the component.
 * @param props.testId A unique identifier for the component instance, used primarily for testing purposes.
 * @returns            A JSX element rendering a section that details the comfort features of the Sonos Ace headphones.
 *
 * @example
 * ```tsx
 * <WearingComfort testId="sonos-ace-comfort" />
 * ```
 */
const WearingComfort = ({testId}: ComponentProps) => {
    const colors = useThemeColors();

    return (
        <StyledContainer data-cy={testId} maxWidth={CONTAINER_FULLSIZE_WIDTH}>
            <InViewAnimContainer baseComponent={Row}>
                <Col lg={6} offset={{xs: 0, xxl: 1}} xs={12} xxl={4}>
                    <H3 as={motion.h2} variants={FadeInAnimation}>Kaum wahrnehmbares Tragegefühl</H3>
                </Col>
                <Visible lg xl xxl>
                    <Col lg={1} xxl={2} />
                </Visible>
                <Col lg={4} xs={12} xxl={3}>
                    <P as={motion.p} variants={FadeInAnimation}>
                        <Color $color={colors.secondaryFontColor}>
                            Das Ohrpolster aus weichem Schaum mit einem Bezug aus weichem Lederimitat sorgt für
                            minimalen Kontakt mit deinem Ohr. Außerdem lässt dich der leichte Rahmen fast vergessen,
                            dass du einen Kopfhörer trägst.
                        </Color>
                    </P>
                </Col>
            </InViewAnimContainer>
        </StyledContainer>
    );
};

WearingComfort.displayName = 'WearingComfort';
WearingComfort.defaultProps = {testId: 'WearingComfort'};

export {WearingComfort};

const StyledContainer = styled(Container)`
    padding-block: ${spacing(14)};

    ${media('md')} {
        padding-block: ${spacing(20)};
    }
`;