import {Spacer} from '@nfq/react-grid';

import {Section} from 'UI/components/layout/Section';

import {Features} from 'UI/modules/Features';
import {FeaturesBig} from 'UI/modules/FeaturesBig';
import {FindStore} from 'UI/modules/FindStore';
import {HitDifferent} from 'UI/modules/HitDifferent';
import {IntuitiveControl} from 'UI/modules/IntuitiveControl';
import {Partners} from 'UI/modules/Partners';
import {Protected} from 'UI/modules/Protected';
import {SoundExperience} from 'UI/modules/SoundExperience';
import {SoundExperienceText} from 'UI/modules/SoundExperienceText';
import {TimeCount} from 'UI/modules/TimeCount';
import {UniqueDesign, UniqueDesignText} from 'UI/modules/UniqueDesign';
import {WearingComfort} from 'UI/modules/WearingComfort';
import {WearingComfortVisuals} from 'UI/modules/WearingComfortVisuals';

/**
 * The `UnderTheFold` Component.
 *
 * @returns A React element representing the `UnderTheFold` component.
 *
 * @example
 * ```tsx
 * const MyComponent = <UnderTheFold testId="myTestId">MyComponent</UnderTheFold>;
 * ```
 */
const UnderTheFold = () => (
    <>
        <Section bgColor="videoBackBg" testId="experienceSection">
            <SoundExperienceText testId="soundExperienceText" />
            <SoundExperience testId="soundExperience" />
        </Section>
        <Section bgColor="whiteBoxBg" testId="featuresSection">
            <FeaturesBig testId="featuresBig" />
            <Features testId="features" />
        </Section>
        <Section bgColor="pageBackground" testId="partnersSection">
            <Partners testId="partnersSection" />
        </Section>
        <HitDifferent testId="hitDifferent" />
        <Section bgColor="linenBoxBg" testId="wearingComfortSection" hasNoPaddingTop>
            <WearingComfort testId="wearingComfort" />
            <WearingComfortVisuals testId="wearingComfortVisuals" />
        </Section>
        <Section bgColor="blackBoxBg" testId="designSection" hasNoPaddingBottom>
            <UniqueDesignText testId="uniqueDesignText" />
            <UniqueDesign testId="uniqueDesign" />
            <Spacer y={{lg: 80, md: 60, xs: 14}} />
            <TimeCount testId="timecount" />
            <IntuitiveControl testId="intuitiveControl" />
            <Protected testId="protected" />
        </Section>
        <Section testId="storesSection" hasNoPaddingBottom>
            <FindStore testId="findStore" />
        </Section>
    </>
);

UnderTheFold.displayName = 'UnderTheFold';

export {UnderTheFold};