/* eslint-disable security/detect-object-injection */

import {useCallback, useEffect, useRef, useState} from 'react';

import {useConfig, useScreenSize} from '@nfq/react-grid';

import {useSlider} from 'UI/components/action/Slider/useSlider';

import {useThemeColors} from 'UI/hooks/useThemeColors';

/**
 * A custom React hook designed to manage the interaction and state of a unique design feature that involves multiple video components within a slider.
 * This hook integrates various functionalities including theme colors, configuration settings, and screen size detection to adapt the feature based on the device.
 * It controls video playback by pausing inactive videos and playing the active one based on the user's selection or viewport visibility. The hook uses `useSlider`
 * to provide responsive slider functionality and leverages multiple `useRef` for video elements to manage direct DOM interactions. Additionally, the state management
 * includes handling the visibility of the slider container to ensure videos are paused when not in view, enhancing performance and user experience.
 *
 * @returns An object containing various states and functions:
 * - `activeOpacity` and `inactiveOpacity`: Opacity values for active and inactive video components.
 * - `colors`: Theme colors obtained from `useThemeColors` for consistent styling.
 * - `currentActiveDetail`: The index of the currently active video.
 * - `firstDetail`, `secondDetail`, `thirdDetail`: Constants representing the indices of the videos, useful for identification and control.
 * - `setSliderContainerInView`: A function to update the visibility state of the slider container.
 * - `sliderContainerRef`: A ref for the slider container to attach to the DOM element for visibility tracking.
 * - `slideRef`: A ref from `useSlider` hook to manage the slider functionality.
 * - `videoRefs`: An array of refs for the video elements.
 * - `viewport`: Configuration for the viewport that triggers active video play.
 * - `viewportSetActive`: A function that sets the active video based on viewport conditions.
 *
 * @example
 * ```ts
 * const {
 *   activeOpacity,
 *   colors,
 *   currentActiveDetail,
 *   firstDetail,
 *   inactiveOpacity,
 *   secondDetail,
 *   setSliderContainerInView,
 *   sliderContainerRef,
 *   slideRef,
 *   thirdDetail,
 *   videoRefs,
 *   viewport,
 *   viewportSetActive,
 * } = useUniqueDesign();
 * ```
 */
export const useUniqueDesign = () => {
    const colors = useThemeColors();
    const config = useConfig();
    const breakpoint = useScreenSize();
    const [currentActiveDetail, setCurrentActiveDetail] = useState(0);
    const [isSliderContainerInView, setSliderContainerInView] = useState(false);

    const {breakpoints} = config;

    const sliderContainerRef = useRef<HTMLDivElement>(null);
    const video1Ref = useRef<HTMLVideoElement>(null);
    const video2Ref = useRef<HTMLVideoElement>(null);
    const video3Ref = useRef<HTMLVideoElement>(null);

    const {selectedIndex, slideRef} = useSlider({
        align: 'start',
        breakpoints: {[`(min-width: ${breakpoints.md}px)`]: {active: false}}
    });

    const videoRefs = [video1Ref, video2Ref, video3Ref];
    const [firstDetail, secondDetail, thirdDetail] = [0, 1, 2] as const;

    const setActive = useCallback((number: number) => {
        [firstDetail, secondDetail, thirdDetail].filter(i => i !== number).forEach(i => pauseVideo(i));

        setCurrentActiveDetail(number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCurrentActiveDetail]);

    const pauseVideo = useCallback((number: number) => {
        videoRefs[number].current?.pause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [video1Ref, video2Ref, video3Ref]);


    const viewportSetActive = useCallback((number: number) => {
        if (['xs', 'sm'].includes(breakpoint)) {
            return;
        }

        setActive(number);
    }, [breakpoint, setActive]);

    useEffect(() => {
        if (['xs', 'sm'].includes(breakpoint)) {
            setActive(selectedIndex);
            [firstDetail, secondDetail, thirdDetail].filter(i => i !== selectedIndex).forEach(i => pauseVideo(i));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, selectedIndex]);

    useEffect(() => {
        if (!isSliderContainerInView) {
            [firstDetail, secondDetail, thirdDetail].forEach(i => pauseVideo(i));
        }

        [firstDetail, secondDetail, thirdDetail]
            .filter(i => i !== currentActiveDetail).forEach(i => pauseVideo(i));

        void videoRefs[currentActiveDetail].current?.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSliderContainerInView, currentActiveDetail]);

    const viewport = {margin: '-49% 0px -49%'};

    const [activeOpacity, inactiveOpacity] = [1, 0.4];

    return {
        activeOpacity,
        colors,
        currentActiveDetail,
        firstDetail,
        inactiveOpacity,
        secondDetail,
        setSliderContainerInView,
        sliderContainerRef,
        slideRef,
        thirdDetail,
        videoRefs,
        viewport,
        viewportSetActive
    };
};