import {Col, Container, media, Row, Spacer, spacing} from '@nfq/react-grid';
import styled from 'styled-components';

import {Color, H2, H5} from 'UI/components/layout/Text';

import {useThemeColors} from 'UI/hooks/useThemeColors';

import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Displays a text-centric component that emphasizes the superior sound experience of a product. The `SoundExperienceText` component
 * uses the `useThemeColors` hook to apply appropriate color theming to the text, enhancing readability and visual appeal. The text is
 * strategically positioned to convey key messages about the product’s sound quality and its meticulous development process. This setup
 * is perfect for marketing contexts where it is crucial to communicate the premium nature and professional tuning of the product.
 *
 * The layout consists of a main headline followed by a detailed subtext that elaborates on the product's features, ensuring the message
 * is both captivating and informative. This design effectively highlights the product's unique selling points and is designed to resonate
 * strongly with audiophiles and casual listeners alike.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for the component, useful for testing purposes.
 * @returns A React component that delivers a powerful narrative about the sound quality of a product, making it an essential part of a comprehensive product presentation.
 *
 * @example
 * ```tsx
 * <SoundExperienceText testId="sound-experience-text-section" />
 * ```
 */
const SoundExperienceText = ({testId}: ComponentProps) => {
    const colors = useThemeColors();

    return (
        <StyledContainer data-cy={testId} maxWidth={CONTAINER_FULLSIZE_WIDTH}>
            <Row>
                <Col lg={6} offset={{lg: 1}} xs={12}>
                    <H2>Eine unvergleichliche Sound Experience</H2>
                    <Spacer y={{md: 12, xs: 8}} />
                    <H5 as="p">
                        <Color $color={colors.secondaryFontColor}>
                            Mit Leidenschaft entwickelt und professionell abgestimmt:
                            Der erste Kopfhörer von Sonos ist da.
                            Erlebe deine Lieblingsinhalte mit dem allerbesten Sound.
                        </Color>
                    </H5>
                </Col>
            </Row>
        </StyledContainer>
    );
};

SoundExperienceText.displayName = 'SoundExperienceText';
SoundExperienceText.defaultProps = {testId: 'SoundExperienceText'};

export {SoundExperienceText};

const StyledContainer = styled(Container)`
    padding-block: ${spacing(14)};

    ${media('md')} {
        padding-block: ${spacing(20)};
    }
`;