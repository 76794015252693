/* eslint-disable max-len */
import React from 'react';

interface MultifunctionButtonProps {
    /**
     * A string representing the CSS class to be applied to the MultifunctionButtonIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the MultifunctionButtonIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the MultifunctionButtonIcon element.
     */
    width?: number | string;
}

/**
 * The `MultifunctionButton` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `MultifunctionButtonProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the MultifunctionButtonIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the MultifunctionButtonIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the MultifunctionButtonIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const MultifunctionButtonComponent = <MultifunctionButton className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const MultifunctionButton = React.forwardRef<SVGSVGElement, MultifunctionButtonProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 78 34"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <rect height="31.5078" rx="15.7539" stroke={color1} width="76.7368" x="0.5" y="1.27344" />
            <rect height="23.3337" rx="11.6669" stroke={color1} width="44.5977" x="4.60938" y="5.35938" />
            <rect height="17.3808" rx="2.61628" stroke={color1} width="5.23257" x="24.2891" y="8.33594" />
        </svg>
    );
});

MultifunctionButton.displayName = 'MultifunctionButton';
MultifunctionButton.defaultProps = {
    className: '',
    color1: '#ffffff',
    height: 34,
    testId: 'MultifunctionButton',
    width: 78
};