import {Col, Container, media, Row, Spacer, spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {AnimatedPicture} from 'UI/components/layout/AnimatedPicture';
import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';
import {Color, H5, P} from 'UI/components/layout/Text';

import {FadeInAnimation} from 'UI/animations/shared';
import {useThemeColors} from 'UI/hooks/useThemeColors';

import {wearingComfort} from 'Images/wearingComfort';
import {wearingFit} from 'Images/wearingFit';
import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Displays visuals and descriptions highlighting the wearing comfort and fit of the Sonos Ace headphones. This component
 * showcases two key features: the individual fit provided by the adjustable stainless steel band and the maximum comfort
 * designed to accommodate various head shapes, sizes, hairstyles, and accessories like glasses. It uses `StyledPicture`
 * components to display images representing these features and `P` components to describe the benefits in detail, ensuring
 * users understand the thought and engineering that went into making the Sonos Ace comfortable for extended use.
 *
 * @param props        The props passed to the component.
 * @param props.testId A unique identifier for the component instance, used primarily for testing purposes.
 * @returns            A JSX element rendering a section with visuals and text describing the comfort and adaptability of the Sonos Ace headphones.
 *
 * @example
 * ```tsx
 * <WearingComfortVisuals testId="sonos-ace-wearing-comfort" />
 * ```
 */
const WearingComfortVisuals = ({testId}: ComponentProps) => {
    const colors = useThemeColors();

    return (
        <StyledContainer data-cy={testId} maxWidth={CONTAINER_FULLSIZE_WIDTH}>
            <Row>
                <Col offset={{xs: 0, xxl: 1}} xs={12} xxl={10}>
                    <GridWrapper>
                        <GridItem>
                            <StyledPicture alt="Individuelle Passform" loading="eager" src={wearingFit} />
                            <InViewAnimContainer baseComponent={GridText}>
                                <Spacer y={{lg: 12, xs: 8}} isNotStretching />
                                <H5 as={motion.h3} variants={FadeInAnimation}>Individuelle Passform</H5>
                                <Spacer y={4} isNotStretching />
                                <P as={motion.p} variants={FadeInAnimation}>
                                    <Color $color={colors.secondaryFontColor}>
                                        Das einstellbare Edelstahlband gewährleistet einen sicheren Sitz. Ein
                                        innovativer Mechanismus innerhalb der Hörmuschel ermöglicht eine einfache und
                                        präzise Anpassung, um Druck auszugleichen und eine außergewöhnliche akustische
                                        Abdichtung zu schaffen.
                                    </Color>
                                </P>
                            </InViewAnimContainer>
                        </GridItem>
                        <GridItem>
                            <StyledPicture alt="Maximaler Tragekomfort" loading="eager" src={wearingComfort} />
                            <InViewAnimContainer baseComponent={GridText}>
                                <Spacer y={{lg: 12, xs: 8}} isNotStretching />
                                <H5 as={motion.h3} variants={FadeInAnimation}>Maximaler Tragekomfort</H5>
                                <Spacer y={4} isNotStretching />
                                <P as={motion.p} variants={FadeInAnimation}>
                                    <Color $color={colors.secondaryFontColor}>
                                        Wir haben beim Design des Sonos Ace unterschiedliche Kopfformen und -größen,
                                        Frisuren und Accessoires wie Brillen berücksichtigt und ihn umfassend getestet,
                                        um maximalen Komfort sicherzustellen – selbst bei stundenlangem Tragen.
                                    </Color>
                                </P>
                            </InViewAnimContainer>
                        </GridItem>
                    </GridWrapper>
                </Col>
            </Row>
        </StyledContainer>
    );
};

WearingComfortVisuals.displayName = 'WearingComfortVisuals';
WearingComfortVisuals.defaultProps = {testId: 'WearingComfortVisuals'};

export {WearingComfortVisuals};

const StyledContainer = styled(Container)`
    padding-block: ${spacing(14)};

    ${media('md')} {
        padding-block: ${spacing(20)};
    }
`;

const GridWrapper = styled.div`
    column-gap: var(--column-gap, 0px);
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    grid-template-rows: fit-content auto fit-content auto;
    width: 100%;

    ${media('lg')} {
        grid-template-columns:
            calc(100% / 12 * 7 - var(--column-gap, 0px) + var(--column-gap, 0px) * 7 / 12)
            calc(100% / 12 * 5 - var(--column-gap, 0px) + var(--column-gap, 0px) * 5 / 12);
        grid-template-rows: fit-content auto;
    }

    ${media('xxl')} {
        grid-template-columns:
            calc(100% / 10 * 6 - var(--column-gap, 0px) + var(--column-gap, 0px) * 6 / 10)
            calc(100% / 10 * 4 - var(--column-gap, 0px) + var(--column-gap, 0px) * 4 / 10);
    }
`;

const GridItem = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-row: span 2;
    grid-template-rows: subgrid;
    width: 100%;

    &:first-child {
        padding-block-end: ${spacing(28)};
    }

    ${media('lg')} {
        &:first-child {
            padding-block-end: ${spacing(0)};
        }
    }
`;

const StyledPicture = styled(AnimatedPicture)`
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
`;

const GridText = styled.div`
    max-width: 57.5rem;
`;