import {type RefObject, useCallback, useEffect, useRef} from 'react';

import {useScreenSize} from '@nfq/react-grid';

import {useVideoPlay} from 'UI/hooks/useVideoPlay';

/**
 * Provides functionality to play a video once and ensures it does not replay.
 * The `useHitDifferent` custom hook manages the state of video playback ensuring that the video can only be played once.
 * It utilizes a `ref` to keep track of whether the video has been played before, and another `ref` to directly manipulate the video element.
 * The hook integrates with a `useVideoPlay` hook to abstract the direct video control methods. Once the video ends, it sets the `played` state
 * to `true` to prevent future replays. This hook is ideal for scenarios where a video should only be viewed once per component lifecycle.
 *
 * @param ref A reference to the video element.
 * @returns An object containing a `handlePlayVideo` function to initiate the video playback and a `videoRef` reference to the video element.
 *
 * @example
 * ```tsx
 * const { handlePlayVideo, videoRef } = useHitDifferent();
 * <video ref={videoRef} src="path/to/video.mp4" />
 * <button onClick={handlePlayVideo}>Play</button>
 * ```
 */
export const useHitDifferentVideo = (ref: RefObject<HTMLVideoElement>) => {
    const played = useRef<boolean>(false);
    const {playVideo} = useVideoPlay(ref);
    const time = useRef<number>(0);
    const screenSize = useScreenSize();

    /**
     *
     */
    const handleTimeUpdate = useCallback(() => {
        if (played.current) {
            time.current = ref.current?.duration ?? 0;
        }

        time.current = ref.current?.currentTime ?? 0;
    }, [ref]);

    const handleVideoEnd = useCallback(() => {
        played.current = true;
        time.current = ref.current?.duration ?? 0;
    }, [ref]);

    useEffect(() => {
        const video = ref.current;

        if (!video) return;

        video.addEventListener('timeupdate', handleTimeUpdate);
        video.addEventListener('ended', handleVideoEnd);

        // eslint-disable-next-line consistent-return
        return () => {
            video.removeEventListener('timeupdate', handleTimeUpdate);
            video.removeEventListener('ended', handleVideoEnd);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleTimeUpdate, handleVideoEnd, ref.current]);

    useEffect(() => {
        const video = ref.current;

        if (!video) return;
        video.currentTime = time.current;

        if (played.current) {
            return;
        }

        playVideo();
    }, [screenSize, ref, playVideo]);

    return {screenSize};
};