/* eslint-disable max-lines-per-function */
import {Col, Container, media, Row, Spacer} from '@nfq/react-grid';
import {AnimatePresence, m as motion} from 'framer-motion';
import styled from 'styled-components';

import {Picture} from 'UI/components/layout/Picture';
import {Color, H4, P} from 'UI/components/layout/Text';

import {highlighterTransition} from 'UI/animations/uniqueDesign';

import {useIntuitiveControl} from './useIntuitiveControl';
import {MultifunctionButton, NoiseCancelling} from 'Images/icons';
import {intuitiveControlImage} from 'Images/intuitiveControl';
import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders an interactive and visually dynamic component focused on showcasing intuitive control features of a product, such as a multifunction button or noise-canceling capabilities.
 * This component utilizes the `useIntuitiveControl` hook to manage which detail is currently active and adjust the visibility and scaling of text content in real-time as users interact with the component.
 * The layout is segmented into image and text columns, where each text block corresponds to a different control feature and is animated to emphasize the current active detail.
 * Visual highlights and interactive text transitions create a narrative around how to use the product effectively, making it easier for users to understand and appreciate the intuitive design of the controls.
 * This component effectively combines detailed explanations with high-impact visuals and animations to enhance user engagement and provide a deep dive into the product's unique features.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for the component, useful for testing purposes.
 * @returns A React component that displays information about the intuitive control features of a product, using animations and interactive elements to engage and inform users.
 *
 * @example
 * ```tsx
 * <IntuitiveControl testId="intuitive-control-section" />
 * ```
 */
const IntuitiveControl = ({testId}: ComponentProps) => {
    const {
        activeOpacity,
        activeScale,
        colors,
        currentActiveDetail,
        firstDetail,
        inactiveOpacity,
        inactiveScale,
        secondDetail,
        slideRef,
        thirdDetail,
        viewport,
        viewportSetActive
    } = useIntuitiveControl();

    return (
        <StyledContainer data-cy={testId} maxWidth={CONTAINER_FULLSIZE_WIDTH}>
            <Row>
                <ImageColumn md={5} xl={6} xs={12}>
                    <ImageWrapper key="Intuitive-control-image-wrapper">
                        <StyledImage
                            key="Intuitive-control-image"
                            alt="Intuitive Steuerung"
                            src={intuitiveControlImage}
                        />
                        <AnimatePresence mode="sync" initial>
                            {currentActiveDetail === secondDetail && (
                                <Highlighter1
                                    key="highlighter1"
                                    animate="animate"
                                    exit="exit"
                                    initial="initial"
                                    variants={highlighterTransition}
                                />
                            )}
                            {currentActiveDetail === thirdDetail && (
                                <Highlighter2
                                    key="highlighter2"
                                    animate="animate"
                                    exit="exit"
                                    initial="initial"
                                    variants={highlighterTransition}
                                />
                            )}
                        </AnimatePresence>
                    </ImageWrapper>
                </ImageColumn>
                <TextColumn ref={slideRef} md={7} xl={5} xs={12} xxl={4}>
                    <SliderContainer>
                        <TextWrapper
                            animate={{
                                opacity: currentActiveDetail === firstDetail ? activeOpacity : inactiveOpacity,
                                scale: currentActiveDetail === firstDetail ? activeScale : inactiveScale
                            }}
                            transition={{duration: 0.5}}
                            viewport={viewport}
                            $first
                            onViewportEnter={() => viewportSetActive(firstDetail)}
                        >
                            <H4 as={motion.p}>
                                <Color $color={colors.primaryFontColorLight}>Intuitive Steuerung</Color>
                            </H4>
                            <Spacer y={6} />
                            <P as={motion.p}>
                                <Color $color={colors.secondaryFontColor}>
                                    Der Sonos Ace optimiert jeden Aspekt deiner Sound Experience.
                                    Drücke einfach die Einschalttaste,
                                    stelle eine Verbindung her und spiele deine Lieblingsmusik ab.
                                </Color>
                            </P>
                        </TextWrapper>
                        <TextWrapper
                            animate={{
                                opacity: currentActiveDetail === secondDetail ? activeOpacity : inactiveOpacity,
                                scale: currentActiveDetail === secondDetail ? activeScale : inactiveScale
                            }}
                            transition={{duration: 0.5}}
                            viewport={viewport}
                            onViewportEnter={() => viewportSetActive(secondDetail)}
                        >
                            <H4 as={motion.p}>
                                <Color $color={colors.primaryFontColorLight}>
                                    Multifunktionstaste
                                </Color>
                            </H4>
                            <Spacer y={6} />
                            <P as={motion.p}>
                                <Color $color={colors.secondaryFontColor}>
                                    Schiebe die Multifunktionstaste nach oben und unten, um die Lautstärke einzustellen.
                                    Drücke die Taste, um Inhalte wiederzugeben und Anrufe anzunehmen.
                                </Color>
                            </P>
                        </TextWrapper>
                        <TextWrapper
                            animate={{
                                opacity: currentActiveDetail === thirdDetail ? activeOpacity : inactiveOpacity,
                                scale: currentActiveDetail === thirdDetail ? activeScale : inactiveScale
                            }}
                            transition={{duration: 0.5}}
                            viewport={viewport}
                            $last
                            onViewportEnter={() => viewportSetActive(thirdDetail)}
                        >
                            <H4 as={motion.p}>
                                <Color $color={colors.primaryFontColorLight}>
                                    Noise Cancelling &amp;&nbsp;Aware Mode
                                </Color>
                            </H4>
                            <Spacer y={6} />
                            <P as={motion.p}>
                                <Color $color={colors.secondaryFontColor}>
                                    Geräuschunterdrückung identifiziert und unterdrückt externe Geräusche
                                    für mitreißenden Sound. Aktiviere den Aware Modus,
                                    damit du alles mitbekommst, was in deiner Umgebung passiert.
                                </Color>
                            </P>
                        </TextWrapper>
                    </SliderContainer>
                </TextColumn>
                <IconColumn md={8} offset={{md: 4, xl: 5}} xl={7} xs={12}>
                    <IconWrapper>
                        <MultifunctionButton />
                        <Spacer x={5} />
                        <P $size="medium">
                            <Color $color={colors.primaryFontColorLight}>
                                Multifunktionstaste
                            </Color>
                        </P>
                    </IconWrapper>
                    <IconWrapper>
                        <NoiseCancelling />
                        <Spacer x={5} />
                        <P $size="medium">
                            <Color $color={colors.primaryFontColorLight}>
                                Noise Cancelling &amp;&nbsp;Aware Mode
                            </Color>
                        </P>
                    </IconWrapper>
                </IconColumn>
            </Row>
            <Spacer y={15} />
        </StyledContainer>
    );
};

IntuitiveControl.displayName = 'IntuitiveControl';
IntuitiveControl.defaultProps = {testId: 'IntuitiveControl'};

export {IntuitiveControl};

const StyledContainer = styled(Container)`
    overflow: hidden;

    ${media('md')} {
        overflow: visible;
    }
`;

const ImageColumn = styled(Col)`
    align-items: center;
    align-self: flex-start;
    container-type: inline-size;
    display: flex;
    justify-content: center;
    position: sticky;

    ${media('md')} {
        bottom: 50rem;
        height: calc(100dvh * 0.8);
        top: calc(50% - (50cqh / 1.2));
    }
`;

const IconColumn = styled(Col)`
    align-items: center;
    background-color: black;
    border-radius: 5rem 0 0 5rem;
    bottom: 10%;
    display: none;
    flex-direction: row;
    gap: 8.8rem;
    margin-bottom: 10%;
    margin-top: 50%;
    min-height: 8rem;
    padding: 1rem 1rem 1rem 4.8rem;
    position: sticky;
    z-index: 10;

    ${media('md')} {
        display: flex;
        visibility: hidden;
    }

    &::after {
        background: black;
        content: '';
        height: 100%;
        left: 100%;
        position: absolute;
        width: 50dvw;
    }
`;

const IconWrapper = styled.div`
    align-items: center;
    display: flex;
    max-width: 50%;
`;

const ImageWrapper = styled.div`
    height: fit-content;
    position: relative;
    width: fit-content;
`;


const StyledImage = styled(Picture)`
    max-height: 80dvh;
    width: 100%;
`;

const TextColumn = styled(Col)`
    position: relative;
`;

const SliderContainer = styled(motion.div)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2.4rem;
    max-width: unset;

    ${media('md')} {
        display: block;
    }
`;

interface TextWrapperProps {
    $first?: boolean;
    $last?: boolean;
}

const TextWrapper = styled(motion.div)<TextWrapperProps>`
    flex: 0 0 calc(100dvw - 8rem);
    height: auto;
    max-width: 40rem;

    ${media('md')} {
        margin-bottom: ${({$last}) => ($last ? '-50%' : null)};
        margin-top: ${({$first}) => ($first ? '80%' : '50%')};
        transform: scale(0.8);
        transform-origin: left top;
        width: 100%;
    }

    ${media('xl')} {
        margin-bottom: ${({$last}) => ($last ? '-100%' : null)};
    }
`;

const Highlighter = styled(motion.div)`
    aspect-ratio: 1 / 1;
    background-color: ${({theme}) => theme.colors.hightlightPointerColor};
    border: 0.2rem solid ${({theme}) => theme.colors.primaryFontColorLight};
    border-radius: 50%;
    height: auto;
    position: absolute;
    transform: translate(-50%, -50%);
    width: clamp(3rem, 12%, 8rem);
`;

const Highlighter1 = styled(Highlighter)`
    left: 49.6%;
    top: 46.4%;
`;

const Highlighter2 = styled(Highlighter)`
    left: 49.5%;
    top: 58.4%;
`;