import {Col, Container, media, Row, Spacer, spacing} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {AnimatedPicture} from 'UI/components/layout/AnimatedPicture';
import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';
import {Color, H5, H6, P} from 'UI/components/layout/Text';

import {FadeInAnimation} from 'UI/animations/shared';
import {useThemeColors} from 'UI/hooks/useThemeColors';

import {hearDetail} from 'Images/hearDetail';
import {Bluetooth, Mikrofon} from 'Images/icons';
import {speakQuality} from 'Images/speakQuality';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a prominent feature section for the Sonos Ace headphones, focusing on the spectacular, room-filling sound
 * experience. This component visually captures the user's attention by displaying an image related to the audio quality
 * and uses typography and color to emphasize the feature of 3D Sound with Dolby Atmos. It succinctly conveys the immersive
 * audio experience that consumers can expect, highlighting industry-leading 3D audio, dynamic head tracking, and Dolby
 * rendering. This section is designed to make the technical aspects of the product accessible and engaging for the viewer,
 * enhancing the overall presentation of the Sonos Ace's capabilities on the website.
 *
 * @param props        The props passed to the component.
 * @param props.testId A unique identifier for the component instance, used primarily for testing purposes.
 * @returns A JSX element that showcases the Sonos Ace headphones' feature of spectacular, room-filling sound.
 *
 * @example
 * ```tsx
 * <Features testId="features-big-sound" />
 * ```
 */
const Features = ({testId}: ComponentProps) => {
    const colors = useThemeColors();

    return (
        <StyledContainer data-cy={testId} maxWidth={1920}>
            <Row>
                <Col offset={{xs: 0, xxl: 1}} xs={12} xxl={11}>
                    <GridWrapper>
                        <GridItem>
                            <RealtiveImageWrapper>
                                <StyledPicture alt="Individuelle Passform" loading="eager" src={hearDetail} />
                                <IconWrap>
                                    <StyledBluetooth color1={colors.whiteBoxBg} />
                                </IconWrap>
                            </RealtiveImageWrapper>
                            <InViewAnimContainer baseComponent={GridText}>
                                <HeadlineWrapper>
                                    <H6 as={motion.p} variants={FadeInAnimation}>
                                        <Color $color={colors.secondaryFontColor}>
                                            Verlustfreies Audio
                                        </Color>
                                    </H6>
                                    <Spacer y={2} isNotStretching />
                                    <H5 as={motion.h2} variants={FadeInAnimation}>Höre jedes Detail</H5>
                                </HeadlineWrapper>
                                <Spacer x={10} y={{lg: 0, xs: 6}} isNotStretching />
                                <P $size="large" as={motion.p} variants={FadeInAnimation}>
                                    <Color $color={colors.secondaryFontColor}>
                                        Streame verlustfreies Audio über Bluetooth® oder USB-C und höre jede einzelne
                                        Note und jeden Beat deiner Musik.²
                                    </Color>
                                </P>
                            </InViewAnimContainer>
                        </GridItem>
                        <GridItem>
                            <RealtiveImageWrapper>
                                <StyledPicture alt="Maximaler Tragekomfort" loading="eager" src={speakQuality} />
                                <IconWrap>
                                    <StyledMikrofon color1={colors.whiteBoxBg} />
                                </IconWrap>
                            </RealtiveImageWrapper>
                            <InViewAnimContainer baseComponent={GridText}>
                                <HeadlineWrapper>
                                    <H6 as={motion.p} variants={FadeInAnimation}>
                                        <Color $color={colors.secondaryFontColor}>
                                            Voice Targeting
                                        </Color>
                                    </H6>
                                    <Spacer y={2} isNotStretching />
                                    <H5 as={motion.h2} variants={FadeInAnimation}>Unvergleichliche Sprachqualität</H5>
                                </HeadlineWrapper>
                                <Spacer x={10} y={{lg: 0, xs: 6}} isNotStretching />
                                <P $size="large" as={motion.p} variants={FadeInAnimation}>
                                    <Color $color={colors.secondaryFontColor}>
                                        Positionsbestimmende Mikrofone richten sich auf deine Stimme aus und
                                        unterdrücken bei Telefon- und Videoanrufen Hintergrundgeräusche.
                                    </Color>
                                </P>
                            </InViewAnimContainer>
                        </GridItem>
                    </GridWrapper>
                </Col>
            </Row>
        </StyledContainer>
    );
};

Features.displayName = 'Features';
Features.defaultProps = {testId: 'Features'};

export {Features};

const StyledContainer = styled(Container)`
    padding-block: ${spacing(14)};

    ${media('md')} {
        padding-block: ${spacing(20)};
    }
`;

const GridWrapper = styled.div`
    column-gap: var(--column-gap, 0px);
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    grid-template-rows: fit-content auto fit-content auto;
    width: 100%;

    ${media('lg')} {
        grid-template-columns:
            calc(100% / 12 * 5 - var(--column-gap, 0px) + var(--column-gap, 0px) * 5 / 12)
            calc(100% / 12 * 7 - var(--column-gap, 0px) + var(--column-gap, 0px) * 7 / 12);
        grid-template-rows: fit-content auto;
    }

    ${media('xxl')} {
        grid-template-columns:
            calc(100% / 11 * 5 - var(--column-gap, 0px) + var(--column-gap, 0px) * 5 / 11)
            calc(100% / 11 * 6 - var(--column-gap, 0px) + var(--column-gap, 0px) * 6 / 11);
    }
`;

const GridItem = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-row: span 2;
    grid-template-rows: subgrid;
    width: 100%;

    &:first-child {
        padding-block-end: ${spacing(28)};
    }

    ${media('lg')} {
        &:first-child {
            padding-block-end: ${spacing(0)};
        }
    }
`;

const RealtiveImageWrapper = styled.div`
    height: 100%;
    position: relative;
`;

const StyledPicture = styled(AnimatedPicture)`
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
`;

const IconWrap = styled.div`
    align-items: center;
    border: 2px solid ${({theme}) => theme.colors.whiteBoxBg};
    border-radius: 50%;
    bottom: 2rem;
    display: flex;
    height: 4.8rem;
    justify-content: center;
    left: 2rem;
    position: absolute;
    width: 4.8rem;

    ${media('lg')} {
        bottom: 3rem;
        height: 6rem;
        left: 3rem;
        width: 6rem;
    }
`;

const StyledBluetooth = styled(Bluetooth)`
    height: 1.8rem;
    width: 1.8rem;

    ${media('lg')} {
        height: 3.6rem;
        width: 3.6rem;
    }
`;

const StyledMikrofon = styled(Mikrofon)`
    height: 1.6rem;
    width: 1.6rem;

    ${media('lg')} {
        height: 3rem;
        width: 3rem;
    }
`;

const GridText = styled.div`
    display: flex;
    flex-direction: column;
    padding-block-start: ${spacing(8)};

    ${media('lg')} {
        flex-direction: row;
        padding-block-start: ${spacing(12)};
    }
`;

const HeadlineWrapper = styled.div`
    ${media('lg')} {
        flex: 0 0 calc(50% - 2rem);
    }
`;