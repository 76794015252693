import {m as motion} from 'framer-motion';

import {useCount} from './useCount';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The end value for the counter animation.
     */
    end: number;
    /**
     * The start value from which the counter begins counting.
     */
    start: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a counter that animates from a start value to an end value when the component enters the viewport.
 * The `Count` component uses a hook to manage and animate the number increment, providing a visual indicator
 * that counts up to a target number. This is typically used for displaying dynamic stats or figures that capture
 * attention through a counting animation. It integrates with the `framer-motion` library to detect when it enters
 * the viewport and triggers the counting animation once on initial visibility.
 *
 * @param props        The component props.
 * @param props.end    The end value for the counter animation.
 * @param props.start  The start value from which the counter begins counting.
 * @param props.testId A unique identifier used for targeting the component during testing.
 * @returns A motion-enhanced span that counts from the start value to the end value upon entering the viewport.
 *
 * @example
 * ```tsx
 * <Count start={0} end={100} testId="unique-counter-id" />
 * ```
 */
const Count = ({end, start, testId}: ComponentProps) => {
    const {counter, startCounter} = useCount(start, end);

    return (
        <motion.span
            data-cy={testId}
            viewport={{
                amount: 0.4,
                once: true
            }}
            onViewportEnter={startCounter}
        >
            {counter}
        </motion.span>
    );
};

Count.displayName = 'Count';
Count.defaultProps = {testId: 'Count'};

export {Count};