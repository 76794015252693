import {useCallback, useEffect, useState} from 'react';

import {useConfig, useScreenSize} from '@nfq/react-grid';

import {useSlider} from 'UI/components/action/Slider/useSlider';

import {useThemeColors} from 'UI/hooks/useThemeColors';

/**
 * A custom React hook that facilitates interaction and dynamic control for components related to intuitive product features.
 * This hook manages the active state of feature details and adjusts component responses based on user interactions and viewport changes.
 * It leverages the `useSlider` hook for smooth transitions between different feature details and applies conditional styling based on device type,
 * ensuring a responsive and engaging user experience. The hook also handles activation of feature details based on viewport visibility, making it
 * particularly effective for interactive displays where the focus shifts dynamically as the user navigates through the content.
 *
 * @returns An object containing:
 * - `activeOpacity`, `inactiveOpacity`: Opacity levels for active and inactive states to visually distinguish between selected and non-selected features.
 * - `activeScale`, `inactiveScale`: Scale transformations for active and inactive states, enhancing the visual impact of the current selection.
 * - `colors`: Theme colors fetched from `useThemeColors` to ensure consistent styling across the component.
 * - `currentActiveDetail`: The index of the currently active feature detail.
 * - `firstDetail`, `secondDetail`, `thirdDetail`: Constants representing indices for easy reference to specific feature details.
 * - `slideRef`: A ref linked to the slider for interaction with the slider functionality.
 * - `viewport`: Configuration for the viewport that triggers feature detail activation.
 * - `viewportSetActive`: A function to set the active feature detail based on viewport interaction, optimized for non-mobile devices.
 *
 * @example
 * ```ts
 * const {
 *   activeOpacity,
 *   activeScale,
 *   colors,
 *   currentActiveDetail,
 *   firstDetail,
 *   inactiveOpacity,
 *   inactiveScale,
 *   secondDetail,
 *   slideRef,
 *   thirdDetail,
 *   viewport,
 *   viewportSetActive,
 * } = useIntuitiveControl();
 * ```
 */
export const useIntuitiveControl = () => {
    const colors = useThemeColors();
    const config = useConfig();
    const breakpoint = useScreenSize();
    const [currentActiveDetail, setCurrentActiveDetail] = useState(0);
    const isMobile = ['xs', 'sm'].includes(breakpoint);

    const {breakpoints} = config;

    const {selectedIndex, slideRef} = useSlider({
        align: 'start',
        breakpoints: {[`(min-width: ${breakpoints.md}px)`]: {active: false}}
    });

    const [firstDetail, secondDetail, thirdDetail] = [0, 1, 2] as const;

    const setActive = useCallback((number: number) => {
        setCurrentActiveDetail(number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCurrentActiveDetail]);


    const viewportSetActive = useCallback((number: number) => {
        if (isMobile) {
            return;
        }

        setActive(number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, setActive]);

    useEffect(() => {
        if (isMobile) {
            setActive(selectedIndex);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, selectedIndex]);

    const viewport = {margin: '-49% 0px -40%'};

    const [activeOpacity, inactiveOpacity] = [1, 0.4];
    // eslint-disable-next-line @nfq/no-magic-numbers
    const [activeScale, inactiveScale] = [1, isMobile ? 1 : 0.8];

    return {
        activeOpacity,
        activeScale,
        colors,
        currentActiveDetail,
        firstDetail,
        inactiveOpacity,
        inactiveScale,
        secondDetail,
        slideRef,
        thirdDetail,
        viewport,
        viewportSetActive
    };
};