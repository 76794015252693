import {Col, Container, media, Row, Spacer, Visible} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {Count} from 'UI/components/layout/Count';
import {InViewAnimContainer} from 'UI/components/layout/InViewAnimContainer';
import {Picture} from 'UI/components/layout/Picture';
import {Color, H1XXL, H4, P} from 'UI/components/layout/Text';

import {FadeInAnimation, SimpleFadeInAnimation} from 'UI/animations/shared';
import {useThemeColors} from 'UI/hooks/useThemeColors';

import {timeCountImage} from 'Images/timeCount';
import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a component designed to display key performance statistics of a product, specifically focusing on battery life and charging time.
 * This component utilizes the `useThemeColors` hook for consistent theming and animations to enhance visibility and engagement as the viewer scrolls.
 * Text elements and numerical counters are animated to reveal crucial data points dynamically, such as "30+ hours of battery life" and "3 minutes of charging
 * for 3 hours of perfect sound." The layout is organized into three columns, with each column highlighting a different aspect of the product's performance.
 * The component is styled to ensure the text is visually appealing and the information is easy to understand, making it highly effective for marketing or educational purposes.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for the component, useful for testing purposes.
 * @returns A React component that provides an animated, informative presentation of the product's time-related features, such as battery life and charging time.
 *
 * @example
 * ```tsx
 * <TimeCount testId="time-count-section" />
 * ```
 */
const TimeCount = ({testId}: ComponentProps) => {
    const colors = useThemeColors();

    return (
        <Container data-cy={testId} maxWidth={CONTAINER_FULLSIZE_WIDTH}>
            <Row>
                <InViewAnimContainer baseComponent={LeftCol} md={4} xs={12}>
                    <StyledHeadline as={motion.p} variants={SimpleFadeInAnimation}>
                        <Color $color={colors.primaryFontColorLight}>
                            <Count end={30} start={0} testId="timeCountAkku" />+
                        </Color>
                    </StyledHeadline>
                    <Spacer maxY={5} y={5} />
                    <StyledSubline as={motion.p} variants={FadeInAnimation}>
                        <Color $color={colors.primaryFontColorLight}>
                            Stunden <Visible xl xxl><br /></Visible>Akkulaufzeit³
                        </Color>
                    </StyledSubline>
                    <Spacer maxY={5} y={5} />
                    <P as={motion.p} variants={FadeInAnimation}>
                        <Color $color={colors.secondaryFontColor}>
                            Für Hör- oder Sprechzeit mit aktivierter <Visible xxl><br /></Visible>
                            Geräusch&shy;unterdrückung.³
                        </Color>
                    </P>
                    <Spacer y={10} />
                </InViewAnimContainer>
                <CenterCol md={4} xs={12} xxl={3}>
                    <StyledPicture alt="" loading="eager" src={timeCountImage} />
                    <Spacer y={5} />
                </CenterCol>
                <InViewAnimContainer baseComponent={RightCol} md={4} xs={12}>
                    <StyledHeadline as={motion.p} variants={SimpleFadeInAnimation}>
                        <Color $color={colors.primaryFontColorLight}>
                            <Count end={3} start={0} testId="timeCountCharge" />&nbsp;min
                        </Color>
                    </StyledHeadline>
                    <Spacer maxY={5} y={5} />
                    <StyledSubline as={motion.p} variants={FadeInAnimation}>
                        <Color $color={colors.primaryFontColorLight}>
                            laden für 3 Stunden <Nowrap>perfekten</Nowrap> Sound³
                        </Color>
                    </StyledSubline>
                    <Spacer maxY={5} y={5} />
                    <P as={motion.p} variants={FadeInAnimation}>
                        <Color $color={colors.secondaryFontColor}>
                            Bis zu 3 Stunden Ladezeit für ein vollständiges Aufladen.
                        </Color>
                    </P>
                    <Spacer y={14} />
                </InViewAnimContainer>
            </Row>
        </Container>
    );
};

TimeCount.displayName = 'TimeCount';
TimeCount.defaultProps = {testId: 'TimeCount'};

export {TimeCount};

const StyledPicture = styled(Picture)`
    display: block;
    margin: auto;
    max-width: 25rem;
    width: 70%;

    ${media('md')}{
        max-width: 130cqw;
        min-width: 100%;
        transform: translateX(12%);
        width: unset;
    }
`;

const Nowrap = styled.span`
    white-space: nowrap;

`;

const StyledHeadline = styled(H1XXL)`
    ${media('md')} {
        font-size: clamp(8rem, 35cqw, 20rem);
    }
`;
const StyledSubline = styled(H4)`
    ${media('md')} {
        font-size: clamp(2.1rem, 9cqw, 4.8rem);
    }
`;

const LeftCol = styled(Col)`
    container-type: inline-size;

    ${StyledHeadline}, ${StyledSubline}, ${P} {
        text-align: center;

        ${media('md')} {
            text-align: right;
        }
    }
`;

const CenterCol = styled(Col)`
    align-items: center;
    container-type: inline-size;
    display: flex;
    hyphens: auto;
    justify-content: flex-start;
`;

const RightCol = styled(Col)`
    container-type: inline-size;
    hyphens: auto;

    ${StyledHeadline}, ${StyledSubline}, ${P} {
        text-align: center;

        ${media('md')} {
            text-align: left;
        }
    }
`;