/* eslint-disable max-len */
import React from 'react';

interface NoiseCancellingProps {
    /**
     * A string representing the CSS class to be applied to the NoiseCancellingIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the NoiseCancellingIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the NoiseCancellingIcon element.
     */
    width?: number | string;
}

/**
 * The `NoiseCancelling` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `NoiseCancellingProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the NoiseCancellingIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the NoiseCancellingIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the NoiseCancellingIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const NoiseCancellingComponent = <NoiseCancelling className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const NoiseCancelling = React.forwardRef<SVGSVGElement, NoiseCancellingProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38 38"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <circle cx="18.8475" cy="18.8007" r="17.8632" stroke={color1} />
            <circle cx="18.8527" cy="18.8019" r="15.3058" stroke={color1} />
        </svg>
    );
});

NoiseCancelling.displayName = 'NoiseCancelling';
NoiseCancelling.defaultProps = {
    className: '',
    color1: '#ffffff',
    height: 38,
    testId: 'NoiseCancelling',
    width: 38
};