import {animate, cubicBezier, useMotionValue, useTransform} from 'framer-motion';

/**
 * Provides a hook for creating a numeric counter animation from a start value to an end value.
 * This hook utilizes Framer Motion's `useMotionValue` and `useTransform` to animate a number
 * and return a reactive motion value that can be used directly in the UI. The `startCounter`
 * function triggers the animation, which smoothly transitions the numeric value from start to end
 * using a specific easing curve. This is particularly useful for creating engaging numerical animations
 * in components where numbers need to increment or decrement dynamically in response to user interactions
 * or when they appear in the viewport.
 *
 * @param start The initial value of the counter.
 * @param end   The final value of the counter.
 * @returns An object containing a `counter` representing the current value of the animation, and `startCounter`, a function to initiate the animation.
 *
 * @example
 * ```tsx
 * const { counter, startCounter } = useCount(0, 100);
 * // Use `counter` in your component to display the animated value
 * // Call `startCounter` to begin the animation
 * ```
 */
export const useCount = (start: number, end: number) => {
    const count = useMotionValue(start);
    const counter = useTransform(count, latest => Math.round(latest));

    /**
     * Initiates the animation of the counter from the `start` value to the `end` value.
     * The animation duration is set to 1 second, with a custom cubicBezier easing function for smooth transitions.
     */
    const startCounter = () => {
        void animate(count, end, {
            duration: 1,
            ease: cubicBezier(0.40, 0.80, 0.74, 1.00)
        });
    };

    return {
        counter,
        startCounter
    };
};